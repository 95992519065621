import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as scheduleApi from '../../../api/schedule';
// import * as branchApi from '../../../api/branch';
import {useHistory } from 'react-router-dom/cjs/react-router-dom'; // Link,  

import HeaderFeature from '../../Unit/HeaderFeature';
import ReservationRoomStyleComponent from '../../../components/Reservation/ReservationRoomStyle';
import ReservationShowDateComponent from '../../../components/Reservation/ReservationShowDate';
// import ReservationTotal from '../../../components/Reservation/ReservationTotal';
import ReservationScheduleComponent from '../../../components/Reservation/ReservationSchedule';

import { reservationAction, dataPickUpAction, unitsAction } from '../../../redux/actions/index';
import { coverBranchIDToFullName, checkBranchIDHaveOnArea } from '../../../utils/Branch/SupportBranch';

import { getTodayMonth, increaseTodayWithNumber, checkMonthWithOther2Day, getToday,  } from '../../../utils/Date/SupportDate';
import './reservation.css';

ReservationContainer.propTypes = {
};

function ReservationContainer() {
    const dispatch = useDispatch();
    let history = useHistory();
    // const [showComponentDate, setShowComponentDate] = useState(false);
    // const [showComponentA, setShowComponentA] = useState(false);
    const [showComponentB, setShowComponentB] = useState(true);
    const [showComponentC, setShowComponentC] = useState(true);
    const [showComponentD, setShowComponentD] = useState(true);

    const dataBranchOnState = useSelector(state => state.dataPickUp.reservation);
    const allBranchInform = useSelector(state => state.allBranch);

    const token = useSelector(state => state.token);
    const auth = useSelector(state => state.auth);
    const { role, area } = auth.user;
    const { branchID, month } = useParams();
    const scheduleData = useSelector(state => state.reservation);
  //  const isMultiBranch = (role === 5 || role === 6 || role === 7 || role === 8 || role === 1);
    const isMultiBranch = (role === 5 || role === 6);
    const isMultiArea = (role === 7 || role === 1 || role === 8);

    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6 || role === 5) ? renderListBranch(area) :
        (role === 7 || role === 8) ? renderListBranch(dataBranchOnState.area) :
            (role === 4 && branchID === 1) ? ["Thai Van Lung 1", "Annex"] : [];

 //   var [loading, setLoading] = useState(true);
    var [selectRoomType, setSelectRoomType] = useState("All");

    useEffect(() => { 
        if (role !== undefined) checkDataBranch();
     }, [role]);

    function renderListBranch(areaValue) {
        let list = [];
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            if (listArea.length > 0) {  
                allBranchInform[areaValue].forEach(branch => {
                    list.push(branch.name);
                })
            }
        } else {
            list = []
        }
        return list;
    }

    const handleComponentAFinish = () => { 

        async function changeScroll() {
            const today = getTodayMonth();
            const checkMonth = checkMonthWithOther2Day(today, scheduleData.month);

            if (checkMonth === 1) {
                window.scrollTo(900, 0);
              //  dispatch(unitsAction.hiddenLoading());
              //  setShowComponentB(true); 
           } else {
               const dayOfToday = getToday().slice(8, 10);
               if (Number(dayOfToday) < 10) {
                     window.scrollTo(0, 0);
               } else if (Number(dayOfToday) > 10 && Number(dayOfToday) < 20) {
                    window.scrollTo(400, 0);
               } else {
                    window.scrollTo(1200, 0);
               }
          //     dispatch(unitsAction.hiddenLoading());
          //     setShowComponentB(true); 
           }
        }

        changeScroll()
        
        // .then(() => {
        //     dispatch(unitsAction.hiddenLoading());
        //     setShowComponentB(true); 
        // })


    };
    
    const handleComponentBFinish = () => { 
        setShowComponentC(true); 
    };

    const handleComponentCFinish = () => {
        setShowComponentD(true);
    };


    function checkDataBranch() {
        let newDataBranch ;
        
        if(isMultiArea || isMultiBranch) {
            const checkMultiBranchID = branchID ? (isMultiBranch ? (checkBranchIDHaveOnArea(branchID, area, "check") ? branchID : 0) : branchID) : 0
            newDataBranch = {
                month: month ? month : getTodayMonth(),
                typeView: "month", // glist, week, selected,
                branchID: branchID ? checkMultiBranchID : 0,
                branch: branchID ? checkMultiBranchID === 0 ? "Select Branch" : coverBranchIDToFullName(Number(branchID)) : "Select Branch", //
                area: isMultiBranch ? area :  (branchID ? checkBranchIDHaveOnArea(branchID, "", "find") : "Select Area"),
            }
            if(newDataBranch.branchID === 0 && month) alert("Error branch data !! Please select branch")
            else if(month) loadSchedule(newDataBranch.branchID, month);
            else {}
            dispatch(dataPickUpAction.changeDataReservation(newDataBranch));
        
        } else {
            newDataBranch = {
                month: month ? month : getTodayMonth(),
                typeView: "month", // glist, week, selected,
                branchID: auth.user.branchID,
                branch: "Select Branch",
                area: "Select Area",
            }
            dispatch(dataPickUpAction.changeDataReservation(newDataBranch));
            loadSchedule(auth.user.branchID, newDataBranch.month);
        }
    } 

    const loadSchedule = async (branchID, month) => { //2023-07-Test1
        dispatch(unitsAction.showLoading());
        //console.log("call API .....")
        const takeGuestDataList = await scheduleApi.findGuestDataOnSchedule(branchID, month, month, token);
        dispatch(unitsAction.hiddenLoading());
        dispatch(reservationAction.fillRsvtData({
            schedule: takeGuestDataList.data.scheduleData,
//            dataSchedule.data.data,
            listReservation: takeGuestDataList.data.listReservation,
            listWaiting : takeGuestDataList.data.listWaiting,
            listCancel : takeGuestDataList.data.listCancel
        }));

        dispatch(unitsAction.hiddenLoading());
    };

    function onChangeDataSelect(value, target, option) {
        let branchIdSelect;
        if (role === 0 || role === 3 || role === 4 || role === 2) {
            branchIdSelect = auth.user.branchID;
        } else { 
            if (target  === "branch") {
                const areaFindIndex = dataBranchOnState.area === "Select Area" ? area : dataBranchOnState.area;
                const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
                branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
            }
        }

        let newData;

        if((value === 1 || value === -1) && target === "month") { 
            newData = {
                ...dataBranchOnState,
                month: increaseTodayWithNumber(dataBranchOnState.month, "month", value).slice(0,7)
            };
        } else {
            newData = {
                ...dataBranchOnState,
                branchID: branchIdSelect,
                ["" + target]: value
            };
        }

        if(target === "area") {
            newData.branchID = 0;
            newData.branch = "Select Branch";
        }
        if (target === "month") {
            history.push(`/reservation/${newData.branchID}/${newData.month}`);
            loadSchedule(newData.branchID, newData.month);
        }
        dispatch(dataPickUpAction.changeDataReservation(newData));

        // reset data reservation
        // to={`/reservation/${dataBranchOnState.branchID}/${dataBranchOnState.month}`}
    }

    function onTakeLinkLoadDataOtherBranch() {
       // console.log("kick here onTakeLinkLoadDataOtherBranch")
        loadSchedule(dataBranchOnState.branchID, dataBranchOnState.month);
    }

    return (
        <div style={{width:'maxContent'}} className='rsv-container'>
            {
                dataBranchOnState.loading ? "" : 
                     <div className="rp__title" style={{ width: '50px'}}>
                            <HeaderFeature
                                feature1={onChangeDataSelect}
                                feature2={onTakeLinkLoadDataOtherBranch}
                                dataBranchOnState={dataBranchOnState}
                                type="reservation"
                                data = {{
                                        listArea,          
                                        listBranch,
                                        isMulti : isMultiArea || isMultiBranch,
                                        }}xw
                                title={"AZUMAYA " + 
                                ((role === 5 || role ===6) ? coverBranchIDToFullName(Number(branchID)).toUpperCase() : 
                                coverBranchIDToFullName(Number(auth.user.branchID)).toUpperCase())}
                            />
                    </div>
            }
            {
                ((isMultiBranch || isMultiArea) && branchID === undefined && dataBranchOnState.branchID === 0) ?
                    <div style={{marginTop: '48px', position:'fixed', width:'100%'}}>
                        <img
                        alt="not data"
                        src='https://database.azumayareport.com/img/logo/show_home.png'
                        width="500px"/>
                        <h2>Have not reservation show, please select again branch</h2>
                    </div> :
                        !scheduleData.loading ?
                        <div className="d-flex">
                            <ReservationRoomStyleComponent
                                data={scheduleData}
                                branchID={branchID}
                                selectRoomType={selectRoomType}
                                setSelectRoomType={setSelectRoomType}
                            />
                            <div className="rsv">
                                <ReservationShowDateComponent
                                    type="reservation"
                                   // onFinish={handleComponentDateFinish} 
                                    data={scheduleData} />

                                    <ReservationScheduleComponent
                                                data={scheduleData}
                                                onFinish={handleComponentAFinish} 
                                                listRender = "listReservation"
                                                renderNumber =  {1}
                                                branchID={branchID}
                                                showComponent={true}
                                                selectRoomType={selectRoomType}
                                            /> 

                            {showComponentB ?  <ReservationScheduleComponent
                                    data={scheduleData}
                                    onFinish={handleComponentBFinish} 
                                    listRender = "listReservation"
                                    renderNumber =  {2}
                                    showComponent={showComponentD}
                                    selectRoomType={selectRoomType}
                                /> : <div style={{color:"", marginTop:"24px",fontSize:"1.5rem"}}>Loading ...</div>}

                                {showComponentC && <ReservationShowDateComponent
                                    type="waiting"
                                    data={scheduleData} /> }

                                {showComponentC ? <ReservationScheduleComponent
                                    data={scheduleData}
                                    listRender = "listWaiting"
                                    onFinish={handleComponentCFinish} 
                                    selectRoomType={selectRoomType}
                                    showComponent={showComponentD}
                                />: <div style={{color:"", marginTop:"24px",fontSize:"1.5rem"}}>Loading ...</div>}

                                {showComponentD && <ReservationShowDateComponent
                                    type="cancel"
                                    data={scheduleData} /> }

                                {showComponentD ? <ReservationScheduleComponent
                                    data={scheduleData}
                                    listRender = "listCancel"
                                    showComponent={showComponentD}
                                    selectRoomType={selectRoomType}
                                /> : <div style={{color:"", marginTop:"24px",fontSize:"1.5rem"}}>Loading ...</div> } 
                                <br/>
                                <br/>
                            </div>
                        </div> : <div style={{marginTop: '54px', position:'fixed', width:'100%'}}>
                        <img
                        alt="Have not data"
                        src='https://database.azumayareport.com/img/logo/show_home.png'
                        width="500px"/>
                        <h2>Loading data DATABASE, Please waiting for system ...</h2>
                        <h2>If can't load data, please logout and login again</h2>
                    </div>
            }
        </div>
    );
}

export default ReservationContainer;
