import React, { useState, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker, { CalendarContainer } from "react-datepicker";
import NumberFormat from 'react-number-format';

// import { } from '@fortawesome/free-solid-svg-icons';
import { faHandPointDown, faEdit } from '@fortawesome/free-regular-svg-icons';
import { changeDataToDDMMYYYY2 } from '../../../utils/Date/SupportDate';
import { UserContext } from '../../../container/MakeRoom/index';

import RoomDropDown from '../../Unit/RoomDropDown';
import DropDownAction from '../../../features/DropDown';
//--CSS-
import './makeRoomDataRoom.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-datepicker/dist/react-datepicker.css";

function MakeRoomDataRoomDetails(props) {
    const { style } = useContext(UserContext);
    const dataMakeRoom = useSelector(state => state.makeRoom);
    const dataMakeRoomOther = useSelector(state => state.makeRoomOther);
    const auth = useSelector(state => state.auth);
    const { role, branchID } = auth.user;
    const isEdit = dataMakeRoomOther.area !== "cancel" && (role === 0 || role === 2 || role === 3);
    const [roomError, setRoomError] = useState(false);
    let valueTimeSave = useRef("");

   // const REQUEST_CASE_EXTENT = dataMakeRoom.listRequest1;
    const REQUEST_CASE = {
        0: "",
        1: "Company have contract 5% OFF",
        2: "Company have contract free laundry 120.000vnd/day",
        3: "Company have contract 5% OFF + free laundry 120.000vnd/day",
    };

    const REQUEST_CASE2 = {
        0: "",
        1: "Company have contract 5% OFF",
        2: "Company have contract free laundry 5.5$/day",
        3: "Company have contract 5% OFF + free laundry 5.5$/day",
    }

    function renderRequest1Extend() {
        var result = [];

        if (dataMakeRoom.listRequest1.length !== 0) {
            dataMakeRoom.listRequest1.forEach((item, index) => {
                result.push(<button className="btn btn-success mr-2 mt-1"
                    onClick={() => onChangeData(item.value, "request1", "extend")}>
                    {item.name}</button>)
            })
        }

        // result.push(<button className="btn btn-primary"
        //                         onClick={()=>props.onOpenSettingRequest1()}>Edit</button>)

        result.push( <FontAwesomeIcon
                        icon={faEdit}
                        onClick={()=>props.onOpenSettingRequest1()}
                        style={style.icon} />)
        return result;
    }

    function onChangeData(value, target, option) {
        // console.log("value", value)
        // console.log("target", target)

         if((target === "timeEarly" || target === "timeLateOut") && valueTimeSave.current === "") {
            valueTimeSave.current = dataMakeRoom.listSchedule[option][target];
         }
         props.onChangeValueMakeRoom(value, target, option);
    }

    // function onBlurCheckTimeFormat(value, target, option) {
    //     const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);
    //     if (isValid) {
    //         valueTimeSave.current = "";
    //     } else {
    //         props.onChangeValueMakeRoom(valueTimeSave.current, target, option);
    //         valueTimeSave.current = "";
    //     }
    // }

    function checkRoomNumber(value, index) {
        if (dataMakeRoom.listRoomPrice[dataMakeRoom.roomSelect]) {
            setRoomError(false);
        } else setRoomError(true);
    }

    function onChangeRequest(type, request) {
        switch (request) {
            // eslint-disable-next-line no-lone-blocks
            case 1: {
                if(branchID === 11 || branchID === 97) {
                //    props.onChangeValueMakeRoom(REQUEST_CASE2[type], "request1", type);
                    onChangeData(REQUEST_CASE2[type], "request1", "");
                } else {
                  //  props.onChangeValueMakeRoom(REQUEST_CASE[type], "request1", type);
                   onChangeData(REQUEST_CASE[type], "request1", type);
                }

                break;
            };
            // eslint-disable-next-line no-lone-blocks
            case 2: {
                break;
            };
            default: break;
        }
    }

    const DatePickUpCalenderContainer = ({ className, children }) => {
        return (
          <div style={{ padding: "3px", background: "#216ba5", color: "#fff" }}>
            <CalendarContainer className={className}>
              <div style={{ position: "relative" }}>{children}</div>
            </CalendarContainer>
          </div>
        );
      };

    function renderSchedule() {
        const index = dataMakeRoom.scheduleSelect;
        const dataRender = dataMakeRoom.listSchedule[index];
        //backgroundColor: 'aqua',
        return (
            <div style={{  paddingTop:'4px' }}>
                {/* Room */}
                <div style={{ position: "relative" }}
                     className="d-flex align-items-center makeRoom_children ">
                    <label className='makeRoom_label ml-2'>Room</label>
                    <input
                        type="text"
                        maxlength="4"
                        disabled={dataRender.move || (role !== 0 && role !== 2 && role !== 3)}
                        style={{ width: '50px', fontSize: '1.4rem' }}
                        value={dataRender.roomSelect}
                        onFocus={() => setRoomError(false)}
                        onBlur={e => checkRoomNumber(e.target.value, index)}
                        onChange={e => onChangeData(e.target.value, "roomSelect", index)}
                    />
                    <div className="makeRoom_children d-flex">
                        <input
                            onChange={e => onChangeData(e.target.value, "move", index)}
                            type="checkbox"
                            disabled={(role !== 0 && role !== 2 && role !== 3)}
                            id="move"
                            name="move"
                            checked={dataRender.move}
                            value="move" />
                        <label for="move" 
                         className='makeRoom_text'
                        // style={style.text}
                        >Don't move</label>
                    </div>
                    {/* <div style={{
                        color: 'red',
                        fontWeight: 'bold',
                        display: !roomError ? "none" : "block"
                    }}>( Room error)</div> */}

                    <RoomDropDown
                        onBlur={value => onChangeData(value, "roomSelect", index)}
                        data={dataMakeRoom.listRoomNumber}
                        room={dataRender.roomSelect} />
                    {/* Price */}
                    <div className="d-flex ml-5">
                        <label className='makeRoom_label mr-5'>{Number(branchID)  === 11 || Number(branchID)  === 97 ? "Price(USD)": "Price(VND)"}</label>
                        {/* <input
                            type="number"
                            value={dataRender.price}
                            disabled={!isEdit}
                            style={{ width: '70px', fontSize: '1.4rem' }}
                            onChange={e => onChangeData(e.target.value, "price", index)} /> */}

                        <NumberFormat
                            thousandSeparator={true}
                            type="text"
                            name="pickupPrice" 
                            min="0"
                            style={{ width: '70px', fontSize: '1.4rem' }}
                            value={dataRender.price}
                            disabled={!isEdit}
                            onChange={e => onChangeData(e.target.value, "price", index)}
                        />

                        <select
                            onChange={e => onChangeData(e.target.value, "discount", index)}
                            name="discount"
                            id="discount"
                            disabled={!isEdit}
                            className="form-control editor"
                            style={{ width: '100px' }}>
                            <option value="0" selected="">{dataRender.discount === "0" ? "No discount" : dataRender.discount + "%"}</option>
                            <option value="0">{"No discount"}</option>
                            <option value="5">5%</option>
                            <option value="10">10%</option>
                            <option value="15">15%</option>
                            <option value="20">20%</option>
                            <option value="30">30%</option>
                            <option value="40">40%</option>
                            <option value="50">50%</option>
                            <option value="100">100%</option>
                        </select>
                    </div>
                </div>
                {/* Date Stay */}
                <div style={style.children} className="d-flex">
                    <label 
                    // style={style.label} 
                     className='makeRoom_label'
                    >Date Stay</label>
                    <div>
                        <div className='d-flex'>
                            <label 
                                // style={style.text}
                                className="makeRoom_text mr-2"
                                style={{fontWeight:'500'}}
                                >Check In: </label>
                            <div style={style.text} className='makeRoom-checkInOut'>
                                <DatePicker
                                    selected={dataRender.checkin === "" ? "" : new Date(dataRender.checkin)}
                                    dateFormat="dd-MM-yyyy"
                                    onChange={(date) => onChangeData(date, "checkin", index)} 
                                    selectsStart
                                    startDate=""
                                    disabled={(role !== 0 && role !== 2 && role !== 3)}
                                    // startDate={dataRender.checkin === "" ? "" : new Date(dataRender.checkin)}
                                    endDate={dataRender.checkout === "" ? "" : new Date(dataRender.checkout)}
                                    //minDate={dataRender.checkin === "" ? "" :  new Date(dataRender.checkin)} 
                                    monthsShown={2}
                                    popperClassName="some-custom-class"
                                    popperPlacement="bottom-start"
                                    calendarContainer={DatePickUpCalenderContainer}
                                    popperModifiers={[
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [5, 10],
                                          },
                                        },
                                        {
                                          name: "preventOverflow",
                                          options: {
                                            rootBoundary: "viewport",
                                            tether: false,
                                            altAxis: true,
                                          },
                                        },
                                      ]}
                                />
                            </div>
                            <label 
                                className="ml-3 mr-2 makeRoom_text"
                                style={{fontWeight:'500'}}
                                >Check Out: </label>
                            <div style={style.text} className='makeRoom-checkInOut'>
                                <DatePicker
                                    selected={dataRender.checkout === "" ? "" :  new Date(dataRender.checkout)} 
                                    dateFormat="dd-MM-yyyy"
                                    onChange={(date) => onChangeData(date, "checkout", index)} 
                                    selectsEnd
                                    disabled={(role !== 0 && role !== 2 && role !== 3)}
                                    startDate={dataRender.checkin === "" ? "" :  new Date(dataRender.checkin)} 
                                    endDate={dataRender.checkout === "" ? "" :  new Date(dataRender.checkout)} 
                                    minDate={dataRender.checkin === "" ? "" :  new Date(dataRender.checkin)} 
                                    monthsShown={2}
                                    popperClassName="some-custom-class"
                                    popperPlacement="bottom-start"
                                    calendarContainer={DatePickUpCalenderContainer}
                                    popperModifiers={[
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [5, 10],
                                          },
                                        },
                                        {
                                          name: "preventOverflow",
                                          options: {
                                            rootBoundary: "viewport",
                                            tether: false,
                                            altAxis: true,
                                          },
                                        },
                                      ]}
                                />
                            </div>
                            <label className="ml-3 mr-2 makeRoom_text">Nights: </label>
                            <input
                                value={dataRender.nights}
                                type="number"
                                disabled={!isEdit}
                                onChange={e => onChangeData(Math.abs(e.target.value), "nights", index)}
                                style={{ ...style.text, width: '30px' }} />
                        </div>

                        <div className="input-group mt-3" style={{ alignItems: 'center' }}>
                            <div className="input-group-prepend ">
                                <div className="input-group-text">
                                    <input
                                        type="checkbox"
                                        id="shortime"
                                        name="shortime"
                                        checked={dataRender.shortime}
                                        disabled={!isEdit}
                                        onChange={() => onChangeData(!dataRender.shortime, "shortime", index)}
                                        aria-label="Checkbox for following text input" />
                                    <label
                                        // style={style.text}
                                        className="makeRoom_text"
                                        disabled={!isEdit}
                                        onClick={() => onChangeData(!dataRender.shortime, "shortime", index)}
                                        for="shortTime">Short time</label>
                                </div>
                            </div>
                            <div className="input-group-prepend ">
                                <div className="input-group-text">
                                    <label className="makeRoom_text" for="`early`CheckIn">Early Check In:</label>
                                </div>
                            </div>

                            <div style={{ fontSize: '1.4rem', flex: '3' }}>
                                <DropDownAction
                                    target="timeEarly"
                                    width="100%"
                                    value={dataRender.timeEarly}
                                    dropdownMenu= {{right:0, width:'100%'}}
                                    widthInput="100%"
                                    indexData={index}
                                    onChange={onChangeData}

                                    disabled={!isEdit}
                                    dropDownItem={ {
                                        "yellow": ["07:00", "10:00","11:00", "12:00", "15:00",]}}
                                    />
                           </div>


                            <div className="input-group-prepend ">
                                <div className="input-group-text">
                                    <label className="makeRoom_text" for="lateOut">Late Out:</label>
                                </div>
                            </div>

                        <div style={{ fontSize: '1.4rem', flex: '3' }}>
                            <DropDownAction
                                //    value={value}
                                target="timeLateOut"
                                width="100%"
                                value={dataRender.timeLateOut}
                                dropdownMenu= {{right:0, width:'100%'}}
                                widthInput="100%"
                                indexData={index}
                                onChange={onChangeData}

                                disabled={!isEdit}
                                dropDownItem={{
                                     "yellow": ["12:00", "18:00", "18:30","21:00", "22:00", ]}}
                                />
                        </div>
                            </div>

                        </div>
                </div>
            </div>
        )
    }

    function renderButtonSchedule() {
        var result = [];

        for (let i = 0; i < dataMakeRoom.listSchedule.length; i++) {
            let index = i;

            const schedule = {
                roomSelect: dataMakeRoom.listSchedule[i]["roomSelect"],
                checkin: dataMakeRoom.listSchedule[i]["checkin"],
                checkout: dataMakeRoom.listSchedule[i]["checkout"],
            }

            result.push(
                <div className="d-flex align-items-center" style={{ position: "relative" }}>
                    <div className={"btn pt-3 pb-3 mr-1 " + (index === dataMakeRoom.scheduleSelect ? "btn-primary" : "")}
                        style={{
                            fontSize: '1.3rem',
                          //  paddingRight: '14px',
                            width: '150px',
                            border: '1px solid black'
                        }}
                        onClick={() => onChangeData(index, "scheduleSelect", "")}
                    >
                        {`R${schedule.roomSelect} (${changeDataToDDMMYYYY2(schedule.checkin).slice(0, 5)}-${schedule.checkout === "" ? "" : changeDataToDDMMYYYY2(schedule.checkout).slice(0, 5)})`}
                    </div>
                    <div style={{
                        fontSize: '1.8rem',
                        color: 'red',
                        right: 4,
                        fontWeight: 'bold',
                        position: 'absolute',
                        cursor: 'pointer',
                        display: ((index === 0 && dataMakeRoom.listSchedule.length === 1) || index === 0) ? 'none' : 'block'
                    }}
                        onClick={() => onConfigDeleteSchedule(index)}
                    >X</div>
                </div>
            )
        }
        return result;
    };

    function onConfigDeleteSchedule(index) {
        // show modal confirm delete schedule
        props.onConfirmDeleteSchedule(index)
    };

    function onClickAddMore() {
        const newData = { ...dataMakeRoom };
        newData.listSchedule.push({
            roomSelect: "",
            move: false,
            discount: dataMakeRoom.listSchedule[0].discount,
            price: "",
            checkin: "",
            checkout: "",
            nights: "",
            shortime: false,
            earlyCheckin: false,
            lateOut: false,
            timeEarly: "15:00",
            timeLateOut: "12:00",
            timeShortTime: "",
        });
        newData.scheduleSelect += 1;

        props.changeAll(newData);
    };

    function onSortListSchedule() {
        props.onSortListSchedule()
    }

    function onAddMoreSameListSchedule() {
        props.onAddMoreSameListSchedule()
    }

    return (
        <div style={style.container} >
            <span className="d-flex mr-2"
                data-toggle="collapse"
                data-target="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample">
                <h3 className="ml-2">* Room Details</h3>
                <FontAwesomeIcon
                    icon={faHandPointDown}
                    style={style.icon} />
            </span>

            <div className="collapse show" id="collapseExample">
                {/* Mail Booker: */}
                <div style={style.children}>
                    <label style={style.label}>Mail Booker:</label>
                    <input
                        type="email"
                        style={{ width: '350px', fontSize: '1.4rem' }}
                        pattern=".+@globex\.com"
                        disabled={!isEdit}
                        placeholder="Enter email"
                        value={dataMakeRoom.mail}
                        onChange={e => props.onChangeValueMakeRoom(e.target.value, "mail", "")}
                    />
                </div>
                {/* make list schedule */}

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {renderButtonSchedule()}
                    {
                        isEdit && <><div className="btn btn-warning p-3"
                                        onClick={() => onClickAddMore()}
                                        style={{ fontSize: '1.2rem', width: '80px' }}>Add More</div>
                                    {/* {(dataMakeRoom.listSchedule.length === 1 && ( Number(branchID) === 15 || Number(branchID) === 99 )) && <div className="btn btn-info p-3"
                                        onClick={() => onAddMoreSameListSchedule()}
                                        style={{ fontSize: '1.2rem', width: '100px' }}>Add Double</div>
                                        }   */}
                                    {(dataMakeRoom.listSchedule.length > 1 && ( Number(branchID) !== 11 )) && <div className="btn btn-success p-3"
                                        onClick={() => onSortListSchedule()}
                                        style={{ fontSize: '1.2rem', width: '80px' }}>Sort</div>
                                    }     
                                </>
                    }  
                </div>

                {renderSchedule()}

                <br></br>
                {/* Payment Method */}
                <div style={style.children}>
                    <label style={style.label}>Payment Method</label>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="individual"
                            checked={dataMakeRoom.payment === "individual"}
                            disabled={!isEdit}
                            onChange={e => props.onChangeValueMakeRoom(e.target.value, "payment", "")} />
                        <label className="form-check-label" for="inlineRadio1" style={style.text}>Individual</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="cash"
                            checked={dataMakeRoom.payment === "cash"}
                            disabled={!isEdit}
                            onChange={e => props.onChangeValueMakeRoom(e.target.value, "payment", "")} />
                        <label className="form-check-label" for="inlineRadio2" style={style.text}>Cash</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="card"
                            checked={dataMakeRoom.payment === "card"}
                            disabled={!isEdit}
                            onChange={e => props.onChangeValueMakeRoom(e.target.value, "payment", "")} />
                        <label className="form-check-label" for="inlineRadio3" style={style.text}>Card</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="company"
                            checked={dataMakeRoom.payment === "company"}
                            disabled={!isEdit}
                            onChange={e => props.onChangeValueMakeRoom(e.target.value, "payment", "")} />
                        <label className="form-check-label" for="inlineRadio4" style={style.text}>Company</label>
                    </div>
                    {
                        (branchID === 15 || branchID === 7 || branchID === 99) && <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5" value="pre-pay"
                                checked={dataMakeRoom.payment === "pre-pay"}
                                disabled={!isEdit}
                                onChange={e => props.onChangeValueMakeRoom(e.target.value, "payment", "")} />
                            <label className="form-check-label" for="inlineRadio5" style={style.text}>Pre-pay</label>
                        </div>
                    }
                </div>

                {/* Requirement (note booking): */}
                <div style={style.children} className="d-flex">
                    <label style={style.label}>Requirement 1:
                        <h5 style={{color:"red"}}>Use ", " to separate requests</h5>
                    </label>
                    <div>
                        <textarea
                            //   onBlur={() => props.onBlur(dataState.request2, "request2")}
                            onChange={e => props.onChangeValueMakeRoom(e.target.value, "request1", "tap")}
                            value={dataMakeRoom.request1}
                            disabled={!isEdit}
                            style={style.textarea}></textarea>
                       {
                        isEdit && <><div>
                            <button className="btn btn-primary" onClick={() => onChangeRequest(1, 1)}>Company 5%</button>
                            <button className="btn btn-primary ml-2" onClick={() => onChangeRequest(2, 1)}>Company laundry</button>
                            <button className="btn btn-primary ml-2" onClick={() => onChangeRequest(3, 1)}>Company 5% + laundry</button>
                            <button className="btn btn-danger ml-2" onClick={() => onChangeRequest(0, 1)}>Clear</button>
                        </div>
                        { branchID === 15 && renderRequest1Extend() }
                        </>
                       } 
                    </div>
                </div>

                <div style={style.children} className="d-flex">
                    <label style={style.label}>Requirement2 (note room):</label>
                    <textarea
                        disabled={!isEdit}
                        onChange={e => props.onChangeValueMakeRoom(e.target.value, "request2", "")}
                        value={dataMakeRoom.request2}
                        style={{ ...style.textarea, minHeight: '100px' }}></textarea>
                </div>
            </div>
        </div >
    )
};

export default MakeRoomDataRoomDetails;
