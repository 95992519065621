import axios from 'axios';

export const findElementBranch = (branchID, type, token) => {
    let newURL = "/branch/find_element/" + branchID + "/" + type;
    return axios.get(`${newURL}`,  {
        headers: { Authorization: token }
    });
}

export const updateElementBranch = (branchID, data,  type, token) => {
    let newURL = "/branch/update_element/" + branchID + "/" + type;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const changeBookingRequest1 = (branchID, data, token) => {
    let newURL = "/branch/change_booking_request1/" + branchID;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const updateExChangeRate = (branchID, data, token) => {
    let newURL = "/branch/update_rate/" + branchID;
    return axios.patch(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

export const getBranchArea = () => {
    let newURL = "/branch/get_area";
    return axios.get(`${newURL}`);
}

export const takeExchangeRate = (branchID) => {
    let newURL = "/branch/"+branchID+"/get_rate_exchange";
    return axios.get(`${newURL}`);
}

// http://localhost:3000/branch/take_list_room/15
export const takeListRoomWithBranch = (branchID, token) => {
    let newURL = "/branch/take_list_room/" + branchID;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}