import { createActions } from 'redux-actions';

export const getType = (reduxAction) => {
    return reduxAction().type;
};

export const authAction = createActions({
    login: undefined,
    checkOnServer: undefined,
    getUser: (payload) => payload,
});

export const modalAction = createActions({
    modalChange: (payload) => payload,
    modalIsOk: undefined,
    modalReset: undefined,
    modalComponent: (payload) => payload,
    modalComponentContent: (payload) => payload
})

export const unitsAction = createActions({
    showLoading: (payload) => payload,
    hiddenLoading: undefined,
    changeContentLoading: (payload) => payload,
    tokenRefresh: (payload) => payload,

    fetchBranchInformation: (payload) => payload,
    resetDataBranchInformation: undefined,

    fetchAllBranchInformation: (payload) => payload,
    resetDataAllBranchInformation: undefined,
    delay: undefined,
});

export const reservationAction = createActions({
    fillRsvtData: (payload) => payload,
    takeRsvtData: (payload) => payload,
    resetRsvtData: undefined,
    // changeHeader: (payload) => payload,
    // changeSubHeader: (payload) => payload,
    // changeSelectNameHeader: (payload) => payload,
});

export const makeRoomAction = createActions({
    takeDataBookingByID: (payload) => payload,
    takaDataBookingBySelect: (payload) => payload,
    takaDataBookingByWaitingList: (payload) => payload,
    fillMakeRoom: (payload) => payload,
    resetMakeRoom: undefined,
    changeChiddenMakeRoom: (payload) => payload,
    changeAllDataChiddenMakeRoom: (payload) => payload,
    updateDataGuestAfterEditMakeRoom: (payload) => payload,

    fillMakeRoomOther: (payload) => payload,
    fillMakeRoomOtherSignature: (payload) => payload,
    resetMakeRoomOther: undefined,
    changeChiddenMakeRoomOther: (payload) => payload,
})

export const companyAction = createActions({
    resetCompany: undefined,
    callApiLoadDataCompany: undefined,
    changeTypeSearch: (payload) => payload,
    fillCompany: (payload) => payload,
    changeValueKeySearch: (payload) => payload,
    changeChildrenCompany: (payload) => payload,
})

export const invoiceManageAction = createActions({
    resetInvoiceManage: undefined,
    fillInvoiceManage: (payload) => payload,
})

export const flightManageAction = createActions({
    resetFlightManage: undefined,
    fillFlightManage: (payload) => payload,
})

export const guestCheckInManageAction = createActions({
    resetGuestCheckInManageManage: undefined,
    fillGuestCheckInManageManage: (payload) => payload,
})

export const guestInformationAction = createActions({
    resetGuestInformation: undefined,
    callApiLoadDataGuestInformation: undefined,
    callApiLoadDataGuestInformationByID: (payload) => payload,
    changeTypeSearchGuestInformation: (payload) => payload,
    fillGuestInformation: (payload) => payload,
    changeValueKeySearchGuestInformation: (payload) => payload,
    changeChildrenGuestInformation: (payload) => payload,
})

export const dataPickUpAction = createActions({
    changeDataReservation: (payload) => payload,
    changeDataCancel: (payload) => payload,
    changeDataWaiting: (payload) => payload,
    changeDataInvoiceManage: (payload) => payload,
    changeDataFlightManage: (payload) => payload,
    changeDataGlist: (payload) => payload,
    checkInManage: (payload) => payload,
    checkOutManage: (payload) => payload,
    changeDataCheckOutAcc: (payload) => payload,
})

export const makeHotelInvoiceAction = createActions({
    fillDataMakeHI: (payload) => payload,
    fillDataMakeHI2: (payload) => payload,
    changeItemMakeHI: (payload) => payload,
    changeListItemMakeHI: (payload) => payload,
    changeListOptionMakeHI: (payload) => payload,
    deleteListOptionMakeHI: (payload) => payload,
    addListOptionMakeHI: (payload) => payload,

    addListServiceOrRequestMakeHI: (payload) => payload,
    deleteListServiceOrRequestMakeHI: (payload) => payload,
    changeListServiceOrRequestMakeHI: (payload) => payload,
    resetMakeHI: undefined,
})

export const glistAction = createActions({
    fillDataGlist: (payload) => payload,
    fillDataGlistByServer: (payload) => payload,
    changeItemGlist: (payload) => payload,
    changeEmptyItem: (payload) => payload,
    changeItemStatusGlist: (payload) => payload,
    changeMakerGList: (payload) => payload,
    reloadDataGlist: (payload) => payload,
    resetDataGlist: (payload) => payload,
})

