import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { informationToast, errorToast } from '../../../utils/Toast/index';
import DatePicker from "react-datepicker";

import * as bookingApi from '../../../api/booking';
import * as branchApi from '../../../api/branch';
import * as invoiceApi from '../../../api/invoice';

import { getNights } from '../../../utils/Date/SupportDate';
import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency';
import DropDownAction from "../../../features/DropDown";
import { RATE_VAT } from "../../../constaints/other/otherConstaints";

import { unitsAction, makeHotelInvoiceAction, modalAction } from '../../../redux/actions';
import NumberFormat from 'react-number-format';
import FooterButton from "../../../utils/FooterButton";
import ModalSupportOption from "../../Unit/Modal/modalSupportOption";
import ModalNotify from "../../../features/ModalNotify";
import './makeHotelInvoice.css'

function MakeHotelInvoice() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    const makeHI = useSelector(state => state.makeHI);
    const [listSelectService, setListSelectService] = useState([]);
    const [listSelectRequest, setListSelectRequest] = useState([]);
    const [click, setClick] = useState(true);
   // const [isEditVAT, setIsEditVAT] = useState(false);
    const auth = useSelector(state => state.auth);
    const { branchID, idRoom, type } = useParams();
    const isVietNamBranch = Number(branchID) !== 11 && Number(branchID) !== 97;
    const isHCMAreaBranch = (Number(branchID) === 1 || Number(branchID) === 4 || Number(branchID) === 9 || Number(branchID) === 96);
    const isHNAreaBranch = (Number(branchID) === 3 || Number(branchID) === 12 || Number(branchID) === 8 || Number(branchID) === 15 || Number(branchID) === 99);

   // console.log("type",type)

   // var keepCheckIncreaseNumberBill = useRef({});
    var isChange = useRef(false);

    var sumScheduleTotal = 0;
    var sumTotal = 0;
    var sumService = 0;
    var sumRequest= 0;
    var vat = 0;
    var totalAccommodation = 0;

    useEffect(() => {
        if (idRoom === "new") {
            // load new invoice
        } else {
            const loadNewData2 = async (token) => {
                dispatch(unitsAction.showLoading());
                const result = await bookingApi.takeDataHotelInvoiceByID2(branchID, type ? type :"single", idRoom, token);
                // console.log("result", result)
                dispatch(makeHotelInvoiceAction.fillDataMakeHI({
                    invoice_id : result.data.invoice_id,
                    version: result.data.version,
                    typeBill: result.data.typeBill,
                    saveTypeBill: result.data.typeBill,
                    name: result.data.name,
                    company: result.data.company,
                    address: result.data.address,
                    tax: result.data.tax,
                    listItem: result.data.listItem,
                    rateUSD: result.data.rateUSD,
                    rateYEN: result.data.rateYen,
                    listService: result.data.listService,
                    listRequest: result.data.listRequest,
                    branchInformation: result.data.branchInformation,
                    currency: result.data.currency,
                    isCompanyPay: result.data.isCompanyPay ? result.data.isCompanyPay : false,
                    isAutoUpdate: true,
                    vatRate: isVietNamBranch ? RATE_VAT : 0.1
                }));

                // document.title = "HI-R" + listScheduleSave[0].roomSelect + "("
                // + listScheduleSave[0].checkIn.slice(8, 10) + "-" + listScheduleSave[0].checkOut.slice(8, 10) + ")"

                dispatch(unitsAction.hiddenLoading());
            }

            if (token) {
                // if (Number(branchID) === 99 ) loadNewData2(token);
                // else loadNewData(token);

                loadNewData2(token)


               // checkDataHotelInvoice(token);
            }
        }
    }, [auth.user.branchID]);

    if(!makeHI.isLoading && "listItem" in makeHI) {
        calculationSumTotal();
    }

    // useEffect(() => {
    //     calculationSumTotal()
    // },[makeHI.isAutoUpdate])
 
    function calculationSumTotal() {
        let sum = 0;
        sumService = 0;
        if (makeHI.listItem.length > 0) {
            //listOptions
            makeHI.listItem.forEach((schedule) => {
                sum += Math.round(changeStringVNDtoNumber(schedule["roomRate" + makeHI.currency])
                    * getNights(schedule.checkIn, schedule.checkOut) * 100) / 100;
                if (schedule.listOptions.length > 0) {
                    schedule.listOptions.forEach(item => {
                        sum += changeStringVNDtoNumber(item.rate);
                    });
                }
            });
        }

        if (makeHI.listService.length > 0) {
            makeHI.listService.forEach(service => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                sumService += Math.round(changeStringVNDtoNumber(service.rate) * 100) / 100;
            })
        }

        if (makeHI.listRequest.length > 0) {
            makeHI.listRequest.forEach(service => {
                sumRequest += Math.round(changeStringVNDtoNumber(service.rate) * 100) / 100;
            })
        }

        sumScheduleTotal = (Math.round(sum * 100) / 100);

        if(makeHI.isAutoUpdate) {
            vat = makeHI.currency !== "USD" ? (Math.round(sumScheduleTotal * makeHI.vatRate)) 
            : isVietNamBranch ? (Math.round(sumScheduleTotal * makeHI.vatRate * 100) / 100) : (Math.round(sumScheduleTotal * 0.1 * 100) / 100) ;

            if(makeHI.isReloadVAT) {
                dispatch(makeHotelInvoiceAction.fillDataMakeHI({
                    vat,
                    isReloadVAT: false,
                }))
            }
        } else {
            vat = changeStringVNDtoNumber(makeHI.vat)
        }

        totalAccommodation = isVietNamBranch ? (Math.round((sumScheduleTotal + vat) * 100) / 100) : (Math.round((sumScheduleTotal + vat) * 100) / 100);
        sumTotal = (totalAccommodation + sumService + sumRequest);
    }

    function renderSchedule() {
        let result = [];

        function renderListOption(dataRender, index) {
            let result2 = [];

            function onChangeListOption(value, target, indexOption) {
                dispatch(makeHotelInvoiceAction.changeListOptionMakeHI({ index: { item: index, option: indexOption }, value, target }))
            }

            dataRender.forEach((item, indexOption) => {
                result2.push(<tr>
                    <td className="text-center" style={{ border: '1px solid black' }} colspan="6">
                        <div className="d-flex justify-content-around justify-content-around" style={{ flex: '10' }}>
                            <div style={{ fontWeight: '600', fontSize: '1.3rem', flex: '6', textAlign: 'right' }} className="mr-2 ml-3">
                                Option {indexOption + 1} : </div>
                            <div style={{ fontSize: '1.4rem', flex: '3' }}>
                                <DropDownAction
                                    //    value={value}
                                    target="content"
                                    width="100%"
                                    value={item.content}
                                    widthInput="255px"
                                    indexData={indexOption}
                                    onChange={onChangeListOption}
                                    dropDownItem={{
                                        "yellow": ["Late Out 50%", "Late Out 100%","Extend one peoples",],
                                        "prime": [ "Early Check In 50%","Early Check In 100%", "No Show", "Late Cancel 50% charge", "Late Cancel 100% charge", "Short time within 3hours charge 50%", "Short time under 3hours charge 100%", "Refund"],
                                    }} />
                            </div>
                            <div className="ml-2" style={{ flex: '1' }}>
                                <button style={{ maxWidth: '50px', }} className="btn btn-danger extend-btn"
                                    onClick={() => dispatch(makeHotelInvoiceAction.deleteListOptionMakeHI({ item: index, option: indexOption }))}
                                >- Del</button>
                            </div>
                        </div>
                    </td>
                    <td style={{ border: '1px solid black', }}>
                        <div className="text-center d-flex align-items-center">
                           {checkShowCurrentType("before")}
                            <NumberFormat
                                className="form-control"
                                data-type="roomrate"
                                thousandSeparator={true}
                                type="text"
                                value={item.rate}
                                onChange={e => dispatch(makeHotelInvoiceAction.changeListOptionMakeHI({
                                    index: { item: index, option: indexOption },
                                    value: e.target.value,
                                    target: "rate"
                                }))}
                                style={{ fontSize: '1.4rem' }}
                            />
                            {checkShowCurrentType("behind")}
                        </div>
                    </td>
                </tr>)
            });

            return result2;
        }

        if(makeHI.listItem.length > 0 ) {
            makeHI.listItem.forEach((item, index) => {
                result.push(
                    <>
                        <tr >
                            <td align="center" rowspan={item.listOptions.length + 1} style={{ border: '1px solid black' }}>
                                <span id="snum">{index + 1}.</span>
                            </td>
                            <td className="text-center d-flex" style={{ border: '1px solid black' }}>
                                <input className="form-control" name="roomtype" type="text"
                                    value={item.type}
                                    disabled={makeHI.typeBill !== "D" && makeHI.typeBill !== "F"}
                                    onChange={e => onChangeItemMakeHI(e.target.value.toLocaleUpperCase(), "type", index)}
                                    style={{ fontSize: '1.4rem' }} />
                                <button className="btn btn-success extend-btn"
                                    onClick={() => dispatch(makeHotelInvoiceAction.addListOptionMakeHI({ index }))}
                                >+ Add</button>
                            </td>
                            <td style={{ border: '1px solid black', fontSize: '1.2rem' }}>
                                <input className="form-control" data-type="roomnumber" name="roomnumber[]" type="text"
                                    value={item.roomSelect}
                                    onChange={e => onChangeItemMakeHI(e.target.value, "roomNumber", index)}
                                    style={{ fontSize: '1.4rem' }} />
                            </td>
                            <td style={{ border: '1px solid black', }}>
                                <DatePicker
                                    selected={item.checkIn === "" ? "" : new Date(item.checkIn)} 
                                    dateFormat="dd-MM-yyyy"
                                    disabled={makeHI.typeBill !== "D" && makeHI.typeBill !== "F"}
                                    onChange={(date) => onChangeItemMakeHI(date, "checkIn", index)} 
                                    style={{ fontSize: '1.4rem' }}
                                    />
                            </td>
                            <td style={{ border: '1px solid black', }}>
                                <DatePicker
                                    selected={item.checkOut === "" ? "" : new Date(item.checkOut)} 
                                    dateFormat="dd-MM-yyyy"
                                    disabled={makeHI.typeBill !== "D" && makeHI.typeBill !== "F"}
                                    onChange={(date) => onChangeItemMakeHI(date, "checkOut", index)} 
                                    style={{ fontSize: '1.4rem' }}
                                    />
                            </td>
                            <td style={{ border: '1px solid black', }}>
                                <div style={{ fontSize: '1.4rem' }} >
                                    {getNights(item.checkIn, item.checkOut) <= 0 ? 0 : getNights(item.checkIn, item.checkOut)}
                                </div>
                            </td>
                            <td style={{ border: '1px solid black', }}>
                                <div className="text-center d-flex align-items-center">
                                   {checkShowCurrentType("before")}
                                    <NumberFormat
                                        className="form-control"
                                        data-type="roomrate"
                                        // currency="usd"
                                        thousandSeparator={true}
                                        type="text"
                                        onChange={e => onChangeItemMakeHI(e.target.value,
                                            "roomRate" + makeHI.currency,
                                            index)}
                                        disabled={makeHI.typeBill !== "D" && makeHI.typeBill !=="F"}
                                        value={item["roomRate" + makeHI.currency]}
                                        style={{ fontSize: '1.4rem' }} />
                                    {checkShowCurrentType("behind")}
                                </div>
                            </td>
                            <td style={{ border: '1px solid black', }}>
                                <div className="text-center d-flex align-items-center">
                                    {checkShowCurrentType("before")}
                                    <NumberFormat
                                        className="form-control"
                                        data-type="roomrate"
                                        thousandSeparator={true}
                                        disabled={makeHI.typeBill !== "D" && makeHI.typeBill !=="F"}
                                        type="text"
                                        value={Math.round((changeStringVNDtoNumber(item["roomRate" + makeHI.currency])
                                            * getNights(item.checkIn, item.checkOut)) * 100) / 100}
                                        style={{ fontSize: '1.4rem' }}
                                    />
                                    {checkShowCurrentType("behind")}
                                </div>
                            </td>
                        </tr>
                        {renderListOption(item.listOptions, index)}
                    </>
                )
            })
        }

        return result;
    }

    function onCheckListSelectService(index) {
        const newList = [...listSelectService];
        if (newList.length === 0) newList.push(index);
        else {
            if (newList.includes(index)) {
                //remote
                const findindex = newList.findIndex((item) => item === index);
                newList.splice(findindex, 1)
            } else newList.push(index);
        }
      //  console.log("newlist service: " , newList)
        setListSelectService(newList);
    }

    function onCheckListSelectRequest(index) {
        const newList = [...listSelectRequest];
        if (newList.length === 0) newList.push(index);
        else {
            if (newList.includes(index)) {
                //remote
                const findindex = newList.findIndex((item) => item === index);
                newList.splice(findindex, 1)
            } else newList.push(index);
        }
       // console.log("newlist request: " , newList)    
        setListSelectRequest(newList);
    }

    function renderListByValue(dataRender, listName) {
        let result = [];
        dataRender.forEach((item, index) => {
            function onChangeListOption(value, target, indexOption) {
                dispatch(makeHotelInvoiceAction.changeListServiceOrRequestMakeHI({
                     index, 
                     value, 
                     target: "content", 
                     listName }))
                //     dispatch(makeHotelInvoiceAction.changeListOptionMakeHI({ index: { item: index, option: indexOption }, value, target }))
            }

            result.push(<tr>
                <th className="text-center">
                    <input
                        checked={listName ==="listRequest" ? 
                           listSelectRequest.includes(index)
                             :listSelectService.includes(index)}
                        type="checkbox"
                        onClick={listName ==="listRequest" ? 
                            () => onCheckListSelectRequest(index) :
                                () => onCheckListSelectService(index)} />
                </th>
                <th className="text-center">{index + 1}</th>
                <th className="text-center" colspan="3">
                    {/* <input className="input"
                        style={{ width: '100%', fontSize: '1.4rem' }}
                        value={item.content}
                        onChange={e => dispatch(makeHotelInvoiceAction.changeListServiceMakeHI({ index, value: e.target.value, target: "content" }))}
                    /> */}

                    <div style={{ fontSize: '1.4rem', flex: '3' }}>
                        <DropDownAction
                            //    value={value}
                            target="content"
                            width="100%"
                            value={item.content}
                            dropdownMenu= {{right:0, width:'100%'}}
                            widthInput="100%"
                            // widthItem="100%"
                            indexData={index}
                            // data={data[index]}
                            onChange={onChangeListOption}
                            //       onBlur={onBlur}
                            dropDownItem={listName ==="listRequest" ? {
                               "blue": ["Smoking", "No Smoking", "VAT request", "No VAT", "Discount 5%", "Company have contract 5% OFF", "Company have contract free Laundry 120.000vnd/day", "Company have contract 5% OFF & free Laundry 120.000vnd/day", "Upgrade from to"],
                            }:{
                                "yellow": ["Laundry fee", "Minibar fee",],
                                "prime": ["Pick UP", "DropOff", "RentCar", "Massage 40 minute", "Massage 70 minute", "Massage 100 minute"],
                            }} />
                    </div>
                </th>
                <th className="text-center d-flex" style={{ width: '100%' }}>
                    <div className={makeHI.currency !== "VND" ? "mr-1 ml-1" : "op-hidden"}
                        style={{ fontSize: '1.4rem' }}
                    >{makeHI.currency === "USD" ? "$" :
                        makeHI.currency === "YEN" ? "¥" : ""}</div>

                    <NumberFormat
                        className="form-control"
                        data-type="roomrate"
                        thousandSeparator={true}
                        name="roomrate[]"
                        type="text"
                        value={item.rate}
                        style={{ fontSize: '1.4rem' }}
                        onChange={e => dispatch(makeHotelInvoiceAction.changeListServiceOrRequestMakeHI({ 
                            index, 
                            value: e.target.value, 
                            target: "rate", 
                            listName 
                        }))}
                    />
                    <div className={(makeHI.currency === "VND" || makeHI.currency === "COMPANY") ? "mr-1 ml-1" : "op-hidden"}
                        style={{ fontSize: '1.4rem' }} >{Number(branchID) === 11 || Number(branchID) === 97 ? "KHR" : "VND"}</div>
                </th>
            </tr>)
        })
        return result;
    };

    function onChangeItemMakeHI(value, target, index) {
        dispatch(makeHotelInvoiceAction.changeItemMakeHI({ index, value, target }))
    };

    function updateRateToBranchInformation(value) {
        const update = async () => {
            try {
                const result = await branchApi.updateExChangeRate(branchID, { value }, token);
                if (result) return informationToast("" + result.data.msg);
                else errorToast("Error when update invoice");
            } catch (error) {
                errorToast("Error when update invoice")
            }
        }

        update();
    }

    function onPrintNewNumberHotelInvoice() {
        setClick(false);
        const LIST_NOT_INCREASE = ["content", "currency", "typeBill", "isCompanyPay"];

        if(isChange.current === false) {
            makeHI.listChange.forEach(item => {
                if (!LIST_NOT_INCREASE.includes(item)) {
                    return isChange.current = true;
                }
            });
        }

        const onChangeScheduleChange = async () => {

            const dataUpload = {
                booking_id: idRoom,
                name: makeHI.name,
                address: makeHI.address,
                company: makeHI.company,
                tax: makeHI.tax,
                currency: makeHI.currency,
                typeBill: makeHI.typeBill,
                listItem: makeHI.listItem,
                listService: makeHI.listService,
                listRequest: makeHI.listRequest,
                listChange: makeHI.listChange,
                invoice_id: makeHI.invoice_id,
                version: makeHI.version,
                isCompanyPay: makeHI.isCompanyPay,
                isChange,
            }

            try {
                const result = await invoiceApi.checkScheduleChange(branchID, dataUpload, token);
                if (result) {
                    if(result.data.isIncrease) {
                        dispatch(modalAction.modalChange({
                            type: "centered",
                            title: "Confirm increase number hotel invoice.",
                            size: 'xs',
                            content: < ModalSupportOption
                                type={11}
                                featureSupport1={() => onSave(true)} //isChange.current
                                featureSupport2={closeModal}
                                itemOption={{}}
                            />
                        }));
                        openModal();
                    }else {
                        onSave(isChange.current);
                    }
                }
                else errorToast("Error when update schedule");
            } catch (error) {
                errorToast("Error when update schedule")
            }
        }

        const onSave = async (isChange) => {
            try {
           
                const dataUpload = {
                    booking_id: idRoom,
                    name: makeHI.name,
                    address: makeHI.address,
                    company: makeHI.company,
                    tax: makeHI.tax,
                    currency: makeHI.currency,
                    typeBill: makeHI.typeBill,
                    listItem: makeHI.listItem,
                    listService: makeHI.listService,
                    listRequest: makeHI.listRequest,
                    listChange: makeHI.listChange,
                    invoice_id: makeHI.invoice_id,
                    version: makeHI.version,
                    isCompanyPay: makeHI.isCompanyPay,
                    isChange,
                }

              //  return console.log("dataUpload", dataUpload)
                await invoiceApi.updateHotelInvoice(branchID, dataUpload, idRoom, token)
                    .then(data => {
                        const onLoadPrint = async () => {
                            const resultData = await invoiceApi.saveHotelInvoicePdf(branchID, {
                                ...makeHI,
                                invoice_id: data.data.invoice_id,
                                version: data.data.version,
                                sumScheduleTotal,
                                sumTotal,
                                sumService,
                                sumRequest,
                                vat,
                                totalAccommodation,
                            }, token);

                            if (resultData) {
                                if (resultData.data.status === 1) {
                                    informationToast("" + resultData.data.msg);
                                    window.open(`/invoice_file/` + resultData.data.link, '_blank')
                                    window.location.reload();
                                }
                            } else errorToast("Error when make HI");
                        }

                        if(data.data.invoice_id) {
                            onLoadPrint();
                        } else errorToast("Error when make Hotel Invoice : have note invoice number")
                        
                    });

            } catch (error) {
                errorToast("Error when make HI");
            }
        }

        if (makeHI.invoice_id === "") {
            onSave(false);
        } else {
            if (isChange.current || !isVietNamBranch) {
                dispatch(modalAction.modalChange({
                    type: "centered",
                    title: "Confirm increase number hotel invoice.",
                    size: 'xs',
                    content: < ModalSupportOption
                        type={11}
                        featureSupport1={() => onSave(isChange.current)}
                        featureSupport2={closeModal}
                        itemOption={{}}
                    />
                }));
                openModal();
            } else {
                onChangeScheduleChange();           
            }
        }

    }

    function renderListRequestOrService(type, listName) {
        return (
            <div className="service mt-5">
            <table className="table table-bordered" id="addmore"
              style={{ width:'95%', margin:'auto' }}
            >
                <tbody>
                    <tr>
                        <th className="text-center">
                            <input type="checkbox" onclick="select_all()" className="check_all" />
                        </th>
                        <th className="text-center" style={{ width: '230px', minWidth: '230px', maxWidth: '230px' }}>No.</th>
                        <th className="text-center" 
                            style={{ width: '530px', }}
                            colspan="3">
                            {type.toLocaleUpperCase() +" DESCRIPTION"}</th>
                        <th className="text-center" style={{ width: '190px' }}>CHARGE</th>
                    </tr>
                    {renderListByValue(makeHI[listName], listName)}
                    <tr id="servicetotal">
                        <td colspan="5" className="text-right">
                            <b>TOTAL SERVICE</b>
                        </td>
                        <td id="service" style={{fontSize:'1.5rem', fontWeight:'600'}}>{
                          type ==="request" ? sumRequest.toLocaleString() : sumService.toLocaleString()}</td>
                    </tr>
                </tbody>
            </table>

            <div className="addService_btn ml-2">
                <div>
                    <button className="btn btn-success ml-2"
                        onClick={() => dispatch(makeHotelInvoiceAction.addListServiceOrRequestMakeHI({ listName }))}
                    >+Add More</button>
                    <button className="btn btn-danger"
                        onClick={() => {
                            dispatch(makeHotelInvoiceAction.deleteListServiceOrRequestMakeHI({
                                listDelete: type ==="request" ? listSelectRequest : listSelectService, 
                                listName}))
                            setListSelectRequest([]);
                        }}
                    >-Delete</button>
                </div>
            </div>

        </div>
        )
    }

    function checkShowCurrentType(type) {
        if(type === "before") {
            // if(isVietNamBranch) {
                return (<div className={makeHI.currency !== "VND" ? "mr-1 ml-1" : "op-hidden"}
                        style={{ fontSize: '1.4rem' }}
                    >{makeHI.currency === "USD" ? "$" :
                        makeHI.currency === "YEN" ? "¥" : ""}</div>)
            // } else return "";
        } else {
            // if(isVietNamBranch) {
                return (<div className={(makeHI.currency === "VND" || makeHI.currency === "COMPANY") ? "mr-1 ml-1" : "op-hidden"}
                style={{ fontSize: '1.4rem' }} >{Number(branchID) === 11 || Number(branchID) === 97 ? "KHR" : "VND"}</div> )
            // }else return ""
        }
    }

    function closeModal() { dispatch(modalAction.modalReset()); }
    function openModal() { dispatch(modalAction.modalIsOk()); }

    return (<div>
        <ModalNotify closeModal={closeModal} />
        <div className="HI_header d-flex justify-content-between mt-5" style={{ width: '1100px' }}>
            <div className="d-flex">
                <div style={{ width: '100%', }} align="right">
                    <img 
                      style={{ width: '170px' }}
                      src="/img/logo/logo_az.png" 
                      className="img-responsive logo"
                      alt="logo" />
                        
                </div>
                <div style={{ textAlign: "left" }}>
                    <div style={{ fontSize: '1.4rem', width: '200px', fontWeight: '500' }}>{`AZUMAYA ${makeHI.branchInformation.name.toUpperCase()} HOTEL`}</div>
                    <div style={{ fontSize: '1.2rem', width: '300px' }}>Add: {makeHI.branchInformation.address}</div>
                    <div style={{ fontSize: '1.2rem' }}>Tel: {makeHI.branchInformation.phone}</div>
                </div>
            </div>
            <div style={{ fontSize: '2rem' }}>
                <span>Invoice Number: </span>
                <strong>{makeHI.invoice_id === "" ? "" : (makeHI.invoice_id + makeHI.saveTypeBill + "(" + makeHI.version + ")")}</strong>
            </div>
        </div>
        <div className="HI_name" style={{ fontSize: '3rem', fontWeight: '600' }}>
            {/* {Number(branchID) !== 11 ? "HOTEL INVOICE" : "RECEIPT INVOICE"} */}
            HOTEL INVOICE
        </div>
        <div className="HI_content" style={{ marginBottom: '32px' }}>
            <div className="HI_content-header d-flex justify-content-between">
                <div>
                    <div className="HI_content-header-item">
                        <label className="label">Guest's name:</label>
                        <input className="input"
                            value={makeHI.name.toLocaleUpperCase()}
                            onChange={e => onChangeItemMakeHI(e.target.value, "name")}
                        />
                    </div>
                    <div className="HI_content-header-item">
                        <label className="label">Company:</label>
                        <input className="input" value={makeHI.company}
                            onChange={e => onChangeItemMakeHI(e.target.value, "company")} />
                    </div>
                    <div className="HI_content-header-item">
                        <label className="label">Address:</label>
                        <input className="input" value={makeHI.address}
                            onChange={e => onChangeItemMakeHI(e.target.value, "address")} />
                    </div>
                    <div className="HI_content-header-item">
                        <label className="label">TAX code:</label>
                        <input className="input" value={makeHI.tax}
                            onChange={e => onChangeItemMakeHI(e.target.value, "tax")} />
                    </div>
                </div>

                {
                   <div className="">
                    <h2>Rate</h2>
                    <div className="col-md-12 mt-2 d-flex" style={{ flex: 5 }}>
                        <div style={{ flex: 4 }}>
                            <div className="d-flex align-items-center">
                                <div>Rate USD:</div>
                                <label className="pick ml-2 mr-2">
                                    <input
                                        name="method"
                                        disabled
                                        maxLength={5}
                                        style={{ width: '50px' }}
                                        value={makeHI.rateUSD}
                                        onChange={e => onChangeItemMakeHI(e.target.value, "rateUSD")}
                                    />
                                </label>
                            </div>
                            <div className="d-flex align-items-center">
                                <div>Rate Yen :</div>
                                <label className="pick ml-2 mr-2">
                                    <input
                                        name="method"
                                        maxLength={3}
                                        style={{ width: '50px' }}
                                        value={makeHI.rateYEN}
                                        onChange={e => onChangeItemMakeHI(e.target.value, "rateYEN")}
                                        onBlur={e => updateRateToBranchInformation(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                }
                

                <div className="col-md-4 col-xs-4"
                // style={{ border: '1px solid yellow' }}
                >
                    <h2>Option select</h2>
                    <div className="col-md-12"></div>
                    <div className="col-md-12">
                        <select className="form-control"
                            style={{ fontSize: '1.3rem' }}
                            onChange={e => onChangeItemMakeHI(e.target.value, "typeBill")}>
                            <option value="A" selected={makeHI.typeBill === "A"} style={{ fontSize: '1.3rem' }}>accommodation (Bill A - room)</option>
                            <option value="B" selected={makeHI.typeBill === "B"} style={{ fontSize: '1.3rem' }}>service (Bill B - service)</option>
                            <option value="C" selected={makeHI.typeBill === "C"} style={{ fontSize: '1.3rem' }}>all (Bill C - room & service)</option>
                            <option value="D" selected={makeHI.typeBill === "D"} style={{ fontSize: '1.3rem' }}>request (Bill D - request)</option>
                            <option value="A&B" selected={makeHI.typeBill === "A&B"} style={{ fontSize: '1.3rem' }}>accomService (Bill A&B)</option>
                            {/* <option value="E" selected={makeHI.typeBill === "F"} style={{ fontSize: '1.3rem' }}>Refund Bill (Bill E)</option> */}
                            {
                                Number(branchID) === 99 
                                && <option value="E" selected={makeHI.typeBill === "E"} style={{ fontSize: '1.3rem' }}>Refund (Bill E - Demo)</option>
                            }
                        </select>
                    </div>
                    <div className={isVietNamBranch ? "col-md-12 mt-2" : "col-md-12 mt-4"} style={{ flex: 5 }}>
                        {
                        <div style={{ flex: 4, textAlign:'left' }}>
                            <div className="radio d-flex">
                               <div style={{width:'90px'}}> {"Currency:"} </div>
                                <label className="pick ml-2 mr-2" style={{width:'50px'}}>
                                    <input
                                        type="radio"
                                        name="currency"
                                        checked={makeHI.currency === "USD"}
                                        onClick={makeHI.currency === "USD" ? "" : () => onChangeItemMakeHI("USD", "currency")}
                                    /> USD
                                </label>
                                {
                                  //  isVietNamBranch &&
                                    <label className="pick ml-2 mr-2" style={{width:'50px'}}>
                                                            <input
                                                                type="radio"
                                                                name="currency"
                                                                checked={makeHI.currency === "VND"}
                                                                onClick={makeHI.currency === "VND" ? "" : () => onChangeItemMakeHI("VND", "currency")}
                                                            /> {isVietNamBranch ? "VND" :"KHR"}
                                                        </label> 
                                }

                                {
                                  //  isVietNamBranch &&
                                    <label className="pick ml-2 mr-2" style={{width:'50px'}}>
                                                            <input
                                                                type="radio"
                                                                name="currency"
                                                                checked={makeHI.currency === "YEN"}
                                                                onClick={makeHI.currency === "YEN" ? "" : () => onChangeItemMakeHI("YEN", "currency")}
                                                            /> JPY
                                                        </label>  
                                }

                                
                            </div>
                        </div>
                        }

                        {
                        // (isHCMAreaBranch || isHNAreaBranch) && 
                            <div style={{ flex: 4, textAlign:'left' }}>
                                <div className="radio d-flex">
                                    <div style={{width:'90px'}}>{"Company pay?:"}</div>
                                    <label className="pick ml-2 mr-2" style={{width:'50px'}}>
                                        <input
                                            type="radio"
                                            name="company"
                                            checked={!makeHI.isCompanyPay}
                                            onClick={() => onChangeItemMakeHI(false, "isCompanyPay")}
                                        /> No
                                    </label>
                                    <label className="pick ml-2 mr-2" style={{width:'50px'}}>
                                            <input
                                                type="radio"
                                                name="company"
                                                checked={makeHI.isCompanyPay}
                                                onClick={() => onChangeItemMakeHI(true, "isCompanyPay")}
                                            /> Yes
                                    </label>                  
                                </div>
                            </div>
                            }

                        { isVietNamBranch && <div style={{ flex: 4, textAlign:'left', }}>
                            <div className="radio d-flex">
                                 <div style={{width:'90px'}}>{"VAT rate :"}</div>
                                 <label className="pick ml-2 mr-2" style={{width:'50px'}}>
                                    <input
                                        type="radio"
                                        name="vatRate"
                                        checked={makeHI.vatRate === 0.1}
                                        onClick={() => onChangeItemMakeHI(0.1, "vatRate")}
                                    /> 10%
                                </label>      
                                <label className="pick ml-2 mr-2" style={{width:'50px'}}>
                                    <input
                                        type="radio"
                                        name="vatRate"
                                        checked={makeHI.vatRate === RATE_VAT}
                                        onClick={() => onChangeItemMakeHI(RATE_VAT, "vatRate")}
                                    /> 8%
                                </label>  
                                <label className="pick ml-2 mr-2" style={{width:'50px'}}>
                                    <input
                                        type="radio"
                                        name="vatRate"
                                        checked={makeHI.vatRate === 0.05}
                                        onClick={() => onChangeItemMakeHI(0.05, "vatRate")}
                                    /> 5%
                                </label>               
                                <label className="pick ml-2 mr-2" style={{width:'50px'}}>
                                    <input
                                        type="radio"
                                        name="vatRate"
                                        checked={makeHI.vatRate === 0}
                                        onClick={() => onChangeItemMakeHI(0, "vatRate")}
                                    /> 0%
                                </label>    
                            </div>
                        </div>
                        }

                    </div>
                </div>

            </div>

            <div className="HI_content-content mt-3">
                <div className="room">
                    <table className="HI_content-content_table">
                        <tbody>
                            <tr>
                                <th className="col-md-1 text-center" style={{ border: '1px solid black', width: '20px' }}>No.</th>
                                <th className="col-md-1 text-center" style={{ border: '1px solid black', width: '400px' }}>
                                    Type of room</th>
                                <th className="col-md-1 text-center" style={{ border: '1px solid black', width: '70px' }}>
                                    Room number</th>
                                <th className="col-md-1 text-center" style={{ border: '1px solid black', width: '100px' }}>
                                    Check-in</th>
                                <th className="col-md-1 text-center" style={{ border: '1px solid black', width: '100px' }}>
                                    Check-out</th>
                                <th className="col-md-1 text-center" style={{ border: '1px solid black', width: '50px' }}>
                                    Night</th>
                                <th className="col-md-1 text-center" style={{ border: '1px solid black', width: '120px' }}>
                                    Room Rate</th>
                                <th className="col-md-1 text-center" style={{ border: '1px solid black', width: '140px' }}>
                                    CHARGE</th>
                            </tr>

                            {renderSchedule()}
                            {/* TOTAL */}
                            <tr style={{ border: '1px solid black' }}>
                                <td style={{ border: '1px solid black', textAlign: 'right', fontSize: '1.3rem', fontWeight: '600', paddingRight: '12px' }} colspan="7">
                                    SUBTOTAL
                                </td>
                                <div className="text-center d-flex align-items-center">
                                    {checkShowCurrentType("before")}
                                    <NumberFormat
                                        className="form-control"
                                        data-type="roomrate"
                                        thousandSeparator={true}
                                        type="text"
                                        disabled
                                        value={sumScheduleTotal}
                                        style={{ fontSize: '1.4rem' }}
                                    />
                                    {checkShowCurrentType("behind")}
                                </div>
                            </tr>
                            <tr style={{ border: '1px solid black' }}>
                                <td 
                                    style={{ border: '1px solid black', textAlign: 'right', fontSize: '1.3rem', fontWeight: '600', paddingRight: '12px' }} 
                                    colspan="7">
                                   <div class="form-check">
                                        <div>
                                            ( auto update VAT ???
                                            <input class="form-check-input" 
                                                type="checkbox" 
                                                checked={makeHI.isAutoUpdate}
                                                onChange={() => onChangeItemMakeHI(!makeHI.isAutoUpdate, "isAutoUpdate")}
                                                id="flexCheckIndeterminate"/>
                                            )

                                            <label class="form-check-label ml-4" for="flexCheckIndeterminate">
                                                  {`VAT (${makeHI.vatRate*100})%`} 
                                            </label>
                                        </div>


                                    </div>
                                </td>
                               
                                <div className="text-center d-flex align-items-center">
                                    {checkShowCurrentType("before")}
                                    <NumberFormat
                                        className="form-control"
                                        data-type="roomrate"
                                        thousandSeparator={true}
                                        type="text"
                                        disabled = {makeHI.isAutoUpdate}
                                        onChange={e => onChangeItemMakeHI(e.target.value, "vat")}
                                        // value={makeHI.vat}
                                      //  value = {makeHI.vat}
                                        value = {vat}
                                        style={{ fontSize: '1.4rem' }}
                                    />
                                    {checkShowCurrentType("behind")}
                                </div>
                            </tr>
                            <tr style={{ border: '1px solid black' }}>
                                <td style={{ border: '1px solid black', textAlign: 'right', fontSize: '1.3rem', fontWeight: '600', paddingRight: '12px' }} colspan="7">
                                    TOTAL ACCOMMODATION
                                </td>
                                <div className="text-center d-flex align-items-center">
                                     {checkShowCurrentType("before")}
                                    <NumberFormat
                                        className="form-control"
                                        data-type="roomrate"
                                        thousandSeparator={true}
                                        disabled
                                        name="roomrate[]"
                                        type="text"
                                        value={totalAccommodation}
                                        style={{ fontSize: '1.4rem' }}
                                    />
                                    {checkShowCurrentType("behind")}
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />

                {/* Request / Service */}
                {renderListRequestOrService("request", "listRequest")}
                {renderListRequestOrService("service", "listService")}
            </div>

            <div className="col-md-12 mt-5">
                <table className="table table-bordered">
                    <tbody>
                        {
                            isVietNamBranch ? <tr>
                                                <th className={makeHI.payment === "company" ? "op-hidden" : "col-md-1 text-center"}>
                                                    {(makeHI.currency === "YEN" && !isHCMAreaBranch && !isHNAreaBranch) ? "JPY" :
                                                      (makeHI.currency === "COMPANY" && !isHCMAreaBranch && !isHNAreaBranch)? "Company Payment (VND)" : 
                                                          ((isHCMAreaBranch || isHNAreaBranch) && makeHI.isCompanyPay) ? "Company Payment (" + makeHI.currency + ")" :makeHI.currency}
                                                </th>
                                               </tr> :
                                                <tr>
                                                    <th className={ "col-md-1 text-center"}>
                                                        {makeHI.isCompanyPay ? `Company Payment (${makeHI.currency === "VND" ? "KHR" : makeHI.currency})`
                                                            : makeHI.currency === "VND" ? "KHR" : makeHI.currency }
                                                    </th>
                                                </tr> 
                        }

                        <tr>
                            <td className={"col-md-1 text-center"}>
                                <div style={{ fontSize: '1.4rem' }}>{(sumTotal).toLocaleString()}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div >
        {
            auth.user.branchID && click && <FooterButton
                button1={"PRINT / DOWN PDF"}
                iconBtn1="faPrint"
            //  width="1150px"
                onClickButton1={onPrintNewNumberHotelInvoice}
        />
        }
        
    </div>)
}

export default MakeHotelInvoice
