import React from "react";
import { useSelector } from "react-redux";

function SupportHk1() {
    const dataGlist = useSelector(state => state.glist);
    const { listSupportHK1 } = dataGlist;

    function renderListHK(dataRender, text) {
        console.log("dataRender", dataRender)
        return (
            <tr style={{height:'140px'}}>
                <td style={{fontSize:'1.5rem', width:'70px', border:'1px solid'}}>{text}</td>
                <td style={{fontSize:'1.8rem', width:'240px', border:'1px solid'}}>{dataRender.in.join(", ")}</td>
                <td style={{fontSize:'1.8rem', width:'240px', border:'1px solid'}}>{dataRender.stay.join(", ")}</td>
                <td style={{fontSize:'1.8rem', width:'240px', border:'1px solid'}}>{dataRender.out.join(", ")}</td>
                <td style={{fontSize:'1.8rem', width:'240px', border:'1px solid'}}>{dataRender.available.join(", ")}</td>
            </tr>
        )    
    }

    return (
    <>
        <table className="support_HK">
            <thead>
                <tr>
                    <th style={{border:'1px solid'}}>Tầng</th>
                    <th style={{border:'1px solid'}}>Check in</th>
                    <th style={{border:'1px solid'}}>Khách ở</th>
                    <th style={{border:'1px solid'}}>Check out</th>
                    <th style={{border:'1px solid'}}>Trống</th>
                </tr>
            </thead>
            <tbody>
                {renderListHK(listSupportHK1.row1, "Tầng 1-4")}
                {renderListHK(listSupportHK1.row2, "Tầng 5-8")}
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>

        <table className="support_HK">
            <thead>
                <tr>
                    <th style={{border:'1px solid'}}>Tầng</th>
                    <th style={{border:'1px solid'}}>Check in</th>
                    <th style={{border:'1px solid'}}>Khách ở</th>
                    <th style={{border:'1px solid'}}>Check out</th>
                    <th style={{border:'1px solid'}}>Trống</th>
                </tr>
            </thead>
            <tbody>
                {renderListHK(listSupportHK1.row1, "Tầng 1-4")}
                {renderListHK(listSupportHK1.row2, "Tầng 5-8")}
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </>
    );
}

export default SupportHk1;
