import React from "react";
// import {  changeDataFullFormat_DDMMYYYY } from "../../../utils/Date/SupportDate";
// import { changeStringVNDtoNumber } from "../../../utils/Currency/SupportCurrency";
import './reservationShowDetail.css';

function ReservationShowDetail(props) {
    const { dataRender, showComponent} = props;

    const { name, roomSelect,
             move,
             special,
            companyName,
            checkin,
            checkout,
            pickUp,
            dropOff,
            room_rate,
            room_rate_show,
            payment,
            other1,
            other2,
            remark,
            maker,
            createdAt} = dataRender;

        return (!showComponent ? <></> : <div>
        <table className="table-show-detail" style={{ maxWidth: '400px', minWidth: '200px', padding: '4px'}}>
            <tbody>
                <tr>
                    <td className="label-field">Room number:</td>
                    <td className="info-field d-flex justify-content-between align-items-center"
                    >{roomSelect}{move ? "(Don't move)" : ""}</td>
                </tr>
                <tr><td className="label-field">Customer</td><td className="info-field w-80">{name}</td></tr>
                <tr><td className="label-field">Request</td><td className="info-field w-80">
                    {/* {checkSpecial()} */}
                    {special}
                </td>
                </tr>
                <tr><td className="label-field">Company</td><td className="info-field w-80">{companyName}</td></tr>
                {/* <tr><td className="label-field">Check In</td><td className="info-field w-80">{changeDataFullFormat_DDMMYYYY(checkin)}</td></tr>
                <tr><td className="label-field">Check Out</td><td className="info-field w-80">{changeDataFullFormat_DDMMYYYY(checkout)}</td></tr> */}
                <tr><td className="label-field">Check In</td><td className="info-field w-80">{checkin}</td></tr>
                <tr><td className="label-field">Check Out</td><td className="info-field w-80">{checkout}</td></tr>
                <tr  className={pickUp === "" ?"op-hidden":""}><td className={pickUp === "" ?"op-hidden":"label-field"}>Pick Up</td>
                <td className="info-field w-80 op-redcolor">
                    {(pickUp)}</td>
                    </tr>
                <tr  className={dropOff === "" ?"op-hidden":""}><td className={dropOff === "" ?"op-hidden":"label-field"}>Drop Off</td>
                <td className="info-field w-80 op-redcolor">
                    {(dropOff)}</td>
                    </tr>
                <tr><td className="label-field">Price</td><td className="info-field w-80">
                    {room_rate_show}
                </td></tr>
                <tr><td className="label-field">Payment</td><td className="info-field w-80">{payment}</td></tr>
                <tr><td className="label-field">Other 1</td><td className="info-field w-80">{other1}</td></tr>
                <tr><td className="label-field">Other 2</td><td className="info-field w-80">{other2}</td></tr>
                <tr><td className="label-field">Remark</td><td className="info-field w-80">{remark}</td></tr>
                <tr><td className="label-field">Maker</td><td className="info-field w-80">{maker}</td></tr>
                <tr><td className="label-field">Created Time </td><td className="info-field w-80">{createdAt}</td></tr>
            </tbody>
        </table>
    </div>)

}

export default ReservationShowDetail;
