import React, { useEffect } from "react";
import StatusTabCheckIn from "./StatusTabCheckIn";
import StatusTabArray from "./StatusTabArray";
import { useSelector } from "react-redux";
import {changeDataToDDMMYYYY} from '../../utils/Date/SupportDate';
import StatusTabArray2 from "./StatusTabArray2";
import './status.css';

function StatusTabGlist(props) {
    const { isFullWith, cssWithBranch } = props;
    // const [note, setNote] = useState("");
    //const [heightNote,setHeightNote] = useState("");
    const dataGlist = useSelector(state => state.glist);
    const dataBranchOnState = useSelector(state => state.dataPickUp.glist);
    // const [showType, setShowType] = useState("1tab");
    const auth = useSelector(state => state.auth);
    const { branchID } = auth.user;

    useEffect(() => {
        var textarea = document.getElementById("YourTextArea");
        var limit = 100; //height limit

        textarea.oninput = function () {
            textarea.style.height = "";
            textarea.style.height = Math.min(textarea.scrollHeight, limit) + "px";
        };
    }, [dataGlist.note]);

    function updateStatusTabArray(target,value) {
        props.updateStatusTabArray(target,value);
    }

    return (<div className="d-flex" style={{width: isFullWith ? "100%" : '600px', marginBottom:'12px', }}>

        <div style={{ fontSize: '1.3rem', }}>
            <div style={{ 
                fontSize: '1.5rem', 
                fontWeight: '600', 
                border: '1px solid black', 
                textAlign: 'center' 
            }}
            className="d-flex">
                <div style={{margin:'auto', padding:'4px'}}>Tình Trạng Phòng ngày {changeDataToDDMMYYYY(dataBranchOnState.day)}
                <button className="btn btn-primary ml-2 no-print"
                       onClick={()=>props.onConfirmReload()}
                        >Reload</button>
                        </div>
            </div>
            <StatusTabCheckIn 
                target = "listGuestCheckIn"
                // showType = {showType}
                // setShowType = {setShowType}
                cssWithBranch={cssWithBranch}
                updateStatusTabArray={updateStatusTabArray}
                branchID = {branchID}
            />
            <StatusTabArray
                title="PHÒNG CHECK OUT"
                isFullWith={isFullWith}
                data = {dataGlist.listGuestCheckOut}
                target = "listGuestCheckOut"
                cssWithBranch={cssWithBranch}
                height={(branchID === 8 || branchID === 9)  ? "100px" : "unset"}
                updateStatusTabArray={updateStatusTabArray}
                branchID = {branchID}
            />
            {branchID !== 9 &&<StatusTabArray
                title="PHÒNG CHECK OUT HOM SAU"
                isFullWith={isFullWith}
                data={dataGlist.listGuestCheckOutNextDay}
                target = "listGuestCheckOutNextDay"
                cssWithBranch={cssWithBranch}
                height={(branchID === 8 || branchID === 9) ? "100px" : "unset"}
                updateStatusTabArray={updateStatusTabArray}
                branchID = {branchID}
            />}
            <StatusTabArray
                title="PHÒNG Ở"
                isFullWith={isFullWith}
                data = {dataGlist.listGuestStay}
                target = "listGuestStay"
                cssWithBranch={cssWithBranch}
                height={(branchID === 8 || branchID === 9) ? "150px" : "unset"}
                updateStatusTabArray={updateStatusTabArray}
                branchID = {branchID}
            />
            {
                (branchID === 7 || branchID === 13) && <>
                            <StatusTabArray2
                                title="PHÒNG BẨN"
                                isFullWith={isFullWith}
                                data = {dataGlist.listDirtyRoom}
                                target = "listDirtyRoom"
                                cssWithBranch={cssWithBranch}
                                height={branchID === 8 ? "150px" : "unset"}
                                updateStatusTabArray={updateStatusTabArray}
                                branchID = {branchID}
                            />
                            <StatusTabArray2
                                title="PHÒNG SẠCH"
                                isFullWith={isFullWith}
                                data = {dataGlist.listCleanRoom}
                                target = "listCleanRoom"
                                cssWithBranch={cssWithBranch}
                                height={branchID === 8 ? "150px" : "unset"}
                                updateStatusTabArray={updateStatusTabArray}
                                branchID = {branchID}
                            />
                            <StatusTabArray2
                                title="PHÒNG BẢO TRÌ"
                                isFullWith={isFullWith}
                                data = {dataGlist.listLockRoom}
                                target = "listLockRoom"
                                cssWithBranch={cssWithBranch}
                                height={branchID === 8 ? "150px" : "unset"}
                                updateStatusTabArray={updateStatusTabArray}
                                branchID = {branchID}
                            />
                </>
            }

            <div className="d-flex mb-5" style={{
                border: '1px solid black',
                fontSize: '2rem',
                fontWeight: '600',
            }}>
            <div style={{ margin: 'auto', padding: '2px' }}> Note</div>
                <textarea
                    id="YourTextArea"
                    value={dataGlist.note}
                    onChange={e => updateStatusTabArray("note", e.target.value)}
                    style={{ 
                        fontSize: branchID === 3 ? '2rem' : '1.4rem',
                        width: '100%', 
                        padding: '4px',
                        height:'auto',
                        minHeight:'auto',
                        maxHeight:'auto'
                        }}>
                </textarea>
            </div >
            {/* Note old */}
            <div className="d-flex mb-5 no-print" >
                <div style={{ margin: 'auto', padding: '2px', width:'50px', fontSize:'1.5rem', border:'1px solid'}} className="no-print"> Note Old Date</div>
                <textarea
                        id="YourTextArea"
                        value={dataGlist.noteOld}
                        className="no-print"
                        onChange={e => updateStatusTabArray("note", e.target.value)}
                        disabled
                        style={{ 
                            fontSize: branchID === 3 ? '2rem' : '1.4rem',
                            border:'1px solid',
                            width: '100%', 
                            padding: '4px',
                            height:'auto',
                            minHeight:'auto',
                            maxHeight:'auto'
                            }}>
                    </textarea>
            </div >
        </div >
    </div>)
}

export default StatusTabGlist;
