import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector,} from 'react-redux';
// // --- support / const
import { getToday } from '../../../../utils/Date/SupportDate'; //getTodayMonth, changeDataToDDMMYYYY,
import * as bookingApi from '../../../../api/booking';

// import './guestNumber.css'
import ModalNotify from '../../../../features/ModalNotify';
import { unitsAction } from '../../../../redux/actions'; //authAction, modalAction,
import CompanyItemComponent from '../../../../components/Company';
import { errorToast, informationToast } from '../../../../utils/Toast';

function GuestCheckInContainer() {
    //let history = useHistory();
    const dispatch = useDispatch();
    const [guestCheckInData, setGuestCheckInData] = useState({
        branchInformation: {
            name: "",
            room: 0,
        },
        content: [],
        roomTotal: 0,
        selectIndex: 0,
        day: getToday(),
        isLoading: true,
    });
    const [selectIndex, setSelectIndex] = useState("")
    const auth = useSelector(state => state.auth);
    const token = useSelector(state => state.token);
    const { branchID,} = auth.user; // role, area, 

    useEffect(()=>{
        const callAPI = async () => {
            dispatch(unitsAction.showLoading());
            try {
                const dataGuestCheckIn = await bookingApi.getDataGuestCheckIn(branchID, guestCheckInData.day, token);
                if(dataGuestCheckIn) {
                   if(dataGuestCheckIn.data.status === 1) {
                        setGuestCheckInData({
                            ...guestCheckInData,
                            isLoading: false,
                            content: dataGuestCheckIn.data.data
                        })

                        if (dataGuestCheckIn.data.data.length > 0) {
                            setSelectIndex(dataGuestCheckIn.data.data[0].passport)
                        }
                        informationToast(dataGuestCheckIn.data.msg)
                   }  else {
                        setGuestCheckInData({
                            ...guestCheckInData,
                            isLoading: false,
                            content: []
                        })
                        informationToast(dataGuestCheckIn.data.msg)
                   }
                } else errorToast("Error when load data")
            } catch (error) {
                errorToast("Error when load data")
            }
            dispatch(unitsAction.hiddenLoading());
        }
        if(branchID) callAPI();
    },[branchID, guestCheckInData.day])

    function onChangeSelectIndex(index) {
        setSelectIndex(index)
    }

    return (
        <React.Fragment>
            <ModalNotify />
            <h1>Guest Check In Information</h1>
            <div>
                <h4>Select day:</h4>
                <input 
                    type="date" 
                    value={guestCheckInData.day}
                    onChange = {e => {
                        setGuestCheckInData({...guestCheckInData, 
                            day: e.target.value})
                    }}
                    
                    ></input>
            </div>
            <div className='d-flex'>
            {
                guestCheckInData.content.length > 0 && <CompanyItemComponent
                    // onOpenHistory={onOpenHistory}
                    // onOpenEditCompany={onOpenEditCompany}
                    type="guestCheckIn"
                    pageIndexShow = {10}
                    selectIndex= {guestCheckInData.selectIndex}
                    onChangeSelectIndex2={onChangeSelectIndex}
                    data={guestCheckInData.content} 
                    />
            }
            {
                guestCheckInData.content.length > 0 &&  <div style={{ flex: 1, width: '50%', }}>
                    <h2 style={{ border: '1px solid black' }}>View Full Pass Port</h2>
                    {
                        guestCheckInData.content.length > 0 && <div style={{height:'710px',overflow:'scroll',position:'relative'}}>
                        <img
                            src={"https://database.azumayareport.com/"+ selectIndex} //guestCheckInData.content[selectIndex].passport}
                            alt="passport"
                            style={{width:'100%'}}
                        ></img>
                    </div>
                      } 
                  </div>
            }
            </div>
        </React.Fragment>
    );

}

export default GuestCheckInContainer;
