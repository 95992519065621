import { makeRoomAction, getType } from '../../actions';
import { increaseTodayWithNumber, getNights } from '../../../utils/Date/SupportDate';

const initialState = {
    loading: true,
    mail: "",
    createNew: true,
    viewPassPort: true,
    indexGuestSelect: 0,
    listGuestSelect: [],
    listRoomNumber: [],
    listRoomPrice: {},
    listRequest1: [],
    listBranchArea: [],
    payment: "individual",
    request1: "",
    request2: "",

    scheduleSelect: 0,
    // totalSchedule: 1,

    listSchedule: [
        {
            roomSelect: "",
            move: false,
            discount: 0,
            price: "",
            checkin: "",
            checkout: "",
            nights: "",
            shortime: false,
            earlyCheckin: false,
            lateOut: false,
            timeEarly: "15:00",
            timeLateOut: "12:00",
            timeShortTime: "",
        }
    ]
}

const makeRoomReducer = (state = initialState, action) => {
    switch (action.type) {
        case getType(makeRoomAction.fillMakeRoom): {
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        }
        case getType(makeRoomAction.changeChiddenMakeRoom): {
            const { value, target, option } = action.payload;
            const newList = { ...state }
            newList[target] = value;

            const newState = { ...state }
            switch (target) {
                case "listGuestSelect": {// add email from guest 1 on list guest
                    if (newState.mail === "" && value.length > 0) newState.mail = value[0].email1;
                    if (value.length === 0) newState.mail = "";
                    newState[target] = value;
                    break;
                }
                case "roomSelect": { // check room select on listPrice
                    if (typeof newState.listRoomPrice["" + value] === 'number') {
                        if (newState.listSchedule[option].discount === "0") newState.listSchedule[option].price = state.listRoomPrice[value];
                        else newState.listSchedule[option].price = Math.round(state.listRoomPrice[value] * (1 - Number(newState.listSchedule[option]["discount"]) / 100) * 100) / 100;
                    } else newState.listSchedule[option].price = "";
                    newState.listSchedule[option][target] = value;
                    break;
                }
                case "move": {
                    newState.listSchedule[option]["move"] = !newState.listSchedule[option]["move"];
                    if (newState.request2 === "" && newState.listSchedule[option]["move"] === true) newState.request2 = "Don't move";
                    if (newState.request2 === "Don't move" && newState.listSchedule[option]["move"] === false) newState.request2 = "";
                    break;
                }
                case "price": {
                    const roomPrice = state.listRoomPrice["" + newState.listSchedule[option]["roomSelect"]];
                    const discount5 = Math.round(roomPrice * 0.95 * 100) / 100;
                    const discount10 = Math.round(roomPrice * 0.9 * 100) / 100;
                    const discount15 = Math.round(roomPrice * 0.85 * 100) / 100;
                    const discount20 = Math.round(roomPrice * 0.8 * 100) / 100;
                    const discount30 = Math.round(roomPrice * 0.7 * 100) / 100;
                    const discount50 = Math.round(roomPrice * 0.5 * 100) / 100;

                    switch (Number(value)) {
                        case discount5: newState.listSchedule[option]["discount"] = "5"; break;
                        case discount10: newState.listSchedule[option]["discount"] = "10"; break;
                        case discount15: newState.listSchedule[option]["discount"] = "15"; break;
                        case discount20: newState.listSchedule[option]["discount"] = "20"; break;
                        case discount30: newState.listSchedule[option]["discount"] = "30"; break;
                        case discount50: newState.listSchedule[option]["discount"] = "50"; break;
                        default: break;
                    }
                    if (value === 0 || value === "0") newState.listSchedule[option]["discount"] = "100";

                    newState.listSchedule[option][target] = value;
                    break;
                }
                case "discount": { // check discount and fix price with it
                    if (value === "0") newState.listSchedule[option]["price"] = state.listRoomPrice["" + newState.listSchedule[option]["roomSelect"]];
                    else newState.listSchedule[option]["price"] = Math.round(state.listRoomPrice["" + newState.listSchedule[option]["roomSelect"]] * (1 - Number(value) / 100) * 100) / 100;
                    newState.listSchedule[option][target] = value;
                    break;
                }
                case "checkin": case "checkout": {
                    var dayIn, dayOut;
                    if (target !== "checkin") {
                        dayIn = new Date(newState.listSchedule[option].checkin);
                        dayOut = new Date(value);
                    } else {
                        dayIn = new Date(value);
                        dayOut = new Date(newState.listSchedule[option].checkout);
                    }
                    //const nights = getNights(dayOut, dayIn) / 86400000;
                    const nights = getNights(dayIn, dayOut);

                    if (nights > 0) {
                        newState.listSchedule[option][target] = value;
                        newState.listSchedule[option].nights = Math.round(nights);
                    } else if (nights === 0) {

                    } else {
                        newState.listSchedule[option][target] = value;
                        newState.listSchedule[option].checkout = "";
                        newState.listSchedule[option].nights = "";
                    }
                    break;
                }
                case "nights": {
                    newState.listSchedule[option].nights = value;
                    newState.listSchedule[option].checkout = increaseTodayWithNumber(newState.listSchedule[option].checkin, "day", value);
                    break;
                }
                case "shortime": {
                    newState.listSchedule[option].shortime = !newState.listSchedule[option].shortime;
                    if (newState.listSchedule[option].shortime && newState.request1 === "" && newState.request2 === "") {
                        newState.request1 = "Short time guest";
                        newState.request2 = "Short time guest";
                    }

                    if (newState.listSchedule[option].shortime === false && newState.request1 === "Short time guest") {
                        newState.request1 = "";
                        newState.request2 = "";
                    }
                    break;
                }
                case "timeEarly": case "timeLateOut": {
                    newState.listSchedule[option][target] = value;
                    break;
                }
                case "listSchedule": {
                    newState.scheduleSelect = 0;
                    newState.listSchedule.splice(option, 1);
                    break;
                }
                case "newListSchedule": {
                    newState.listSchedule = value;
                    break;
                }
                default: newState[target] = value;
            }
            return newState;

        }
        case getType(makeRoomAction.changeAllDataChiddenMakeRoom): {
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        }
        case getType(makeRoomAction.resetMakeRoom): {
            return initialState;
        }
        case getType(makeRoomAction.updateDataGuestAfterEditMakeRoom): {
            const newListGuestSelect = [...state.listGuestSelect];
            const {index, data} = action.payload;

            newListGuestSelect[index] = data;
            return {
              ...state,
              listGuestSelect: newListGuestSelect
            }
        }
        default: return state;
    }
}

export default makeRoomReducer;
