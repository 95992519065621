import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import * as bookingApi from '../../api/booking';
import * as branchApi from '../../api/branch';
import { RATE_VAT } from "../../constaints/other/otherConstaints";
import { unitsAction } from '../../redux/actions';
import { changeDataToDDMMYYYY } from '../../utils/Date/SupportDate';
import { coverNameToID } from '../../utils/Branch/SupportBranch';
import { changeStringVNDtoNumber } from '../../utils/Currency/SupportCurrency';
import { informationToast, errorToast, } from '../../utils/Toast/index'; //warningToast
import './makeBookingBill.css';

function MakeBookingBill() {
    const dispatch = useDispatch();
    const { branchID, idRoom , type} = useParams();
    const isVietNamBranch = (Number(branchID) !== 97 && Number(branchID) !== 11 );
    const token = useSelector(state => state.token);
    const waitingAdd = true;

    const [defaultPrice, setDefaultPrice] = useState({
        service: {},
        listSchedule: []
    });

    const [data, setData] = useState({
        langueage: "eng",
        rateYen: 0,
        currency: !isVietNamBranch ?"$":"VND",
        vatPlus: true,
        branchName: "",
        roomSelect: "",
        name: "",
        company_contract: false,
        company_name: "",
        numberGuest: 1,
        email: "",
        smoking: false,
        high: false,
        vat: false,
        vatRate: !isVietNamBranch ? 1.1 : (1 + RATE_VAT),
        request1: "",
        payment: "",

        listSchedule: [],
        listRoom: {},

        lateOutHour: "00",
        lateOutMinute: "00",
        priceLateOut: 0,
        earlyCheckInHour: "00",
        earlyCheckInMinute: "00",
        priceEarlyCheckIn: 0,
      
        pricePickUp: 0,
        flightPickUp:"",
        hoursPickUp:"",
        minutePickUp:"",
        priceDropOff: 0,
        flightDropOff:"",
        hoursDropOff:"",
        minuteDropOff:"",
        priceRentCar: 0,

        otherServer: "",
        priceOtherServer: 0,

        priceTotal: 0,
        marginTopBottom: 190,

        pickUpFlight: "",
        dropOffFlight: "",
        maker: ""
    });

    const LANGUAGE = {
        jap: {
            title: "予約確認書",
            content: "ご予約内容",
            guestName: "ご宿泊者のお名前:",
            companyName: "会社:",
            mailAddress: "メールアドレス:",
            contract: "契約書:",
            yes: "有り",
            no: "なし",
            checkin: "チェックイン",
            checkout: "チェックアウト",
            nights: "ご宿泊日数",
            guestNumber: "ご人数",
            roomType: "ルームタイプ",
            roomRate: "料金／泊",
            request: "特記事項:",
            smoking: "喫煙",
            noSmoking: "禁煙",
            highFloor: "高層階",
            VAT: "VATインボイス",
            otherRequest: "その他の:",
            summary: "合計",
            total: "合計",
            bookingService: "宿泊サービス",
            rentCarService: "空港送迎サービス",
            otherService: isVietNamBranch ? "その他:" : "他のサービス",
            lateCheckOut: "レイトチェックアウト",
            earlyCheckIn: "アーリーチェックイン",
            pickUp: "ピックアップ",
            flight: "フライトナンバー:",
            dropOff: "ドロップオフ",
            rentCar: "レンタカー",
            include1: isVietNamBranch ? "※サービス料5%、70分以上のフットマッサージ10%割引、朝食、屋上露天風呂は料金に含まれております。" :"※サービス料5%、フットマッサージ10%割引、朝食、屋上露天風呂は料金\nに含まれております。",
            include2: isVietNamBranch ? `消費税(${(data.vatRate - 1).toFixed(2)*100}% VAT)別途` : "消費税(10% VAT)別途",
            include3: isVietNamBranch ? `消費税(${(data.vatRate - 1).toFixed(2)*100}% VAT)込み` : "消費税(10% VAT)込み",
            payment: "お支払い方法:",
            company: "会社",
            cash: "現金",
            card: "カード",
            personal: "個人",
            bookingRule: "【 東屋ホテル規約 】",
            // eslint-disable-next-line no-useless-concat
            bookingRule1: "チェックイン" + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + ": 午後3時から",
            // eslint-disable-next-line no-useless-concat
            bookingRule1_1: "チェックアウト" + '\xa0\xa0\xa0\xa0' + ": 正午12時まで",
            bookingRule1p: "アーリーチェックイン:",
            bookingRule1p_1: "午前10時まで1泊分の100％チャージ",
            bookingRule1p_2: "午前10時以降1泊分の50％チャージ",
            bookingRule2: "レイトチェックアウト:",
            bookingRule2_1: "午後6時まで1泊分の50%チャージ",
            bookingRule2_2: "午後6時以降1泊分の100%チャージ",
            bookingRule3: "予約確定後に宿泊なさらなかった場合は最初の一泊分をお支払いいただきます。",
            bookingRule4: "直前の予約キャンセルの場合のチャージ料 : チェックイン日の15時より",
            bookingRule4_1: "",//"チェックイン日の15時より",
            bookingRule4_2: "24時間以内のキャンセル：一泊分の100%を支払うこと",
            bookingRule4_3: "48時間以内のキャンセル：一泊分の50%を支払うこと",
            bookingRule5: "予定の滞在よりも早くチェックアウトをする場合のチャージ料",
            bookingRule5_1: "予変更後のチェックアウト日の15時より",
            bookingRule5_2: "24時間以内に変更をした場合：一泊分の100%を支払うこと",
            bookingRule5_3: "48時間以内に変更をした場合：一泊分の50%を支払うこと",
            bookingRule6: "おねがい",
            bookingRule6_1: "チェックインの際にこちらの予約確認書を受付にてご提示ください。",
            bookingRule6_2: isVietNamBranch ? "ベトナムの法規に準ずる居住登録のため、ベトナム国籍でないお客様は滞在期限が有効なパスポート、ベトナム国籍のお客様はIDがチェックイン時に必要となります。"
            : "カンボジアの法規に準ずる居住登録のため、カンボジア国籍でないお客様は滞在期限が有効なパスポート、カンボジア国籍のお客様はIDがチェックイン時に必要となります。",
            bookingRule6_3: isVietNamBranch ? "ベトナム財務省の規制により宿泊業におけるVAT INVOICE発行はご滞在中のみ可能です。" : "",
            bookingRule6_4: isVietNamBranch ? "ご希望の場合はチェックイン 日までにお伝えください。":"",
            bookingRule6_5: isVietNamBranch ? "メールにてE-VAT invoiceをお送りさせていただきます。":"",
            bookingRule6_6: isVietNamBranch ? "※法律によりチェックアウト後のVAT発行は対応できませんのでご注意ください。" : "",

            localMap: "【 ロケーションマップ 】",
            receptionRegards: "",
            receptionSign: "受付サイン：",
            guestSign: "ご宿泊者様サイン：",
            footer: "東屋ホテルをお選びいただき誠にありがとうございます。スタッフ一同ご来館を心よりお待ちしています。",
        },
        viet: {
            title: "XÁC NHẬN ĐẶT PHÒNG",
            content: "CHI TIẾT ĐẶT PHÒNG",
            guestName: "Tên khách hàng:",
            companyName: "Công ty:",
            mailAddress: "Địa chỉ mail:",
            contract: "Hợp đồng:",
            yes: "Có",
            no: "Không",
            checkin: "Ngày nhận phòng",
            checkout: "Ngày trả phòng",
            nights: "Số đếm",
            guestNumber: "Số lượng khách",
            roomType: "Loại phòng",
            roomRate: "Giá phòng / đêm",
            request: "CÁC YÊU CẦU ĐẶC BIỆT:",
            smoking: "Hút thuốc",
            noSmoking: "Không hút thuốc",
            highFloor: "Tầng cao",
            VAT: "Hoá đơn đỏ",
            otherRequest: "Yêu cầu khác:",
            summary: "TỔNG KẾT ĐẶT PHÒNG",
            total: "Tổng kết",
            bookingService: "Dịch vụ phòng",
            rentCarService: "Dịch vụ xe",
            otherService: "Yêu cầu khác",
            lateCheckOut: "Trả phòng muộn",
            earlyCheckIn: "Nhận phòng sớm",
            pickUp: "Đón ở sân bay",
            flight: "Số chuyến bay",
            dropOff: "Tiến đến sân bay",
            rentCar: "Xe thuê theo lịch trình",
            include1: "※Giá trên bao gồm: 5% phí phục vụ, giảm 10% dịch vụ massage chân từ suất 70'.",
            include2: `Chưa bao gồm: ${(data.vatRate - 1).toFixed(2)*100}% VAT`,
            include3: `Đã bao gồm: ${(data.vatRate - 1).toFixed(2)*100}% VAT`,
            payment: "Hình thức thanh toán: (vui lòng chọn)",
            company: "Công ty",
            cash: "Tiền mặt",
            card: "Trả thẻ",
            personal: "Cá nhân",
            bookingRule: "【 QUY ĐỊNH ĐẶT PHÒNG 】",
            // eslint-disable-next-line no-useless-concat
            bookingRule1: "Thời gian nhận phòng " + '\xa0\xa0\xa0' + ": Từ 15:00",
            // eslint-disable-next-line no-useless-concat
            bookingRule1_1: "Thời gian trả phòng " + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + ": Trước 12:00",
            bookingRule1p: "Nhận phòng sớm:",
            bookingRule1p_1: "Tính 100% của 1 đêm nếu nhận phòng trước 10:00",
            bookingRule1p_2: "Tính 50% của 1 đêm nếu nhận phòng sau 10:00",
            bookingRule2: "Trả phòng trễ:",
            bookingRule2_1: "Tính 50% của 1 đêm nếu trả phòng trước 18:00",
            bookingRule2_2: "Tính 100% của 1 đêm nếu trả phòng sau 18:00",
            bookingRule3: "NO SHOW(không check in): Tính 1 đêm tiền phòng",
            bookingRule4: "Hủy phòng trễ:",
            bookingRule4_1: "",
            bookingRule4_2: "Trong vòng 24 giờ: tính 100%",
            bookingRule4_3: "Trong vòng 48 giờ: tính 50%",
            bookingRule5: "Check out sớm",
            bookingRule5_1: "",
            bookingRule5_2: "Trong vòng 24 giờ: tính 50%",
            bookingRule5_3: "Trong vòng 48 giờ: tính 100%",
            bookingRule6: "Xin lưu ý:",
            bookingRule6_1: "Xin xuất trình giấy đặt phòng cho lễ tân khi đến check in.",
            bookingRule6_2: "Mang theo giấy tờ, chứng mình thư, căn cước, hộ chiếu... khi nhận phòng.",
            bookingRule6_3: isVietNamBranch ? "Theo quy định của Bộ Tài chính Việt Nam, HÓA ĐƠN VAT cho chỗ ở chỉ được xuất trong thời gian bạn lưu trú.":"",
            bookingRule6_4: isVietNamBranch ? "Nếu bạn cần HÓA ĐƠN VAT, vui lòng cho chúng tôi biết cho đến ngày nhận phòng. Chúng tôi sẽ gửi hóa đơn E-VAT qua email.":"",
            bookingRule6_5: isVietNamBranch ? "※Chúng tôi không thể xuất VAT sau khi thanh toán do quy định của Bộ Tài chính Việt Nam" : "",

            localMap: "【  VỊ TRÍ CỦA KHÁCH SẠN 】",
            receptionRegards: "",
            receptionSign: "Chứ ký của lễ tân：",
            guestSign: "Chữ ký của khách hàng：",
            footer: "Cảm ơn quý khách đã ưu ái sử dụng dịch vụ của khách sạn Azumaya. \n Trân trọng.",
        },
        eng: {
            title: "BOOKING CONFIRMATION",
            content: "BOOKING DETAILS",
            guestName: "Customer’s name:",
            companyName: "Company:",
            mailAddress: "Email address:",
            contract: "Contract:",
            yes: "Have",
            no: "Non",
            checkin: "Arrival date",
            checkout: "Departure date",
            nights: "Number of night",
            guestNumber: "Number of guest",
            roomType: "Room type",
            roomRate: "Price / night",
            request: "SPECIAL REQUESTS:",
            smoking: "SMOKING",
            noSmoking: "NON SMOKING",
            highFloor: "HIGH FLOOR",
            VAT: "VAT INVOICE",
            otherRequest: "OTHER:",
            summary: "BOOKING SUMMARY",
            total: "total",
            bookingService: "Accommodation",
            rentCarService: "Car Service",//"Airport transfer",
            otherService: "Others Service",
            lateCheckOut: "LATE CHECK OUT",
            earlyCheckIn: "EARLY CHECK IN",
            pickUp: "PICK UP AIRPORT",
            flight: "Flight number:",
            dropOff: "DROP OFF AIRPORT",
            rentCar:"RENT CAR",
            include1: isVietNamBranch ?
                       "Price is included:(' 5% service charge, 10% discount Foot Massage 70 minutes, daily breakfast, open air hot bath-tub.')" :
                       "Price is included: (' 5% service charge, 10% discount Foot Massage, daily breakfast, open air hot bath-tub.')",
            include2: isVietNamBranch ? `Excluded ${(data.vatRate - 1).toFixed(2)*100}% VAT` : `Excluded 10% VAT`,
            include3: isVietNamBranch ? `Included ${(data.vatRate - 1).toFixed(2)*100}% VAT` : `Included 10% VAT`,
            payment: "MODE OF PAYMENT (Please make a choice ):",
            company: "Company",
            cash: "Cash",
            card: "Card",
            personal: "Individual",
            bookingRule: "【 AZUMAYA POLICY 】",
            // eslint-disable-next-line no-useless-concat
            bookingRule1: "CHECK-IN " + '\xa0\xa0\xa0\xa0\xa0\xa0' + ": After 15:00",
            // eslint-disable-next-line no-useless-concat
            bookingRule1_1: "CHECK-OUT " + '\xa0\xa0\xa0' + ": Until 12:00",
            bookingRule1p: "EARLY CHECK-IN:",
            // bookingRule1p_1: "Check in early until 10:00 : 100% charged",
            // bookingRule1p_2: "Check in early from 10:00 : 50% charged",
            bookingRule1p_1: "Until 10:00 : 100% charged",
            bookingRule1p_2: "From 10:00 : 50% charged",
            bookingRule2: "CHECK-OUT LATE:",
            // bookingRule2_1: "Check out late until 18:00 : 50% charged",
            // bookingRule2_2: "Check out late from 18:00 : 100% charged",
            bookingRule2_1: "Until 18:00 : 50% charged",
            bookingRule2_2: "From 18:00 : 100% charged",
            bookingRule3: "NO SHOW: The equivalent of one night charged",
            bookingRule4: "LATE CANCELLATION : Made prior to 15:00 of Check in date",
            bookingRule4_1: "",
            bookingRule4_2: "Less than 24 hours , 100% charged",
            bookingRule4_3: "Less than 48 hours , 50% charged",
            bookingRule5: isVietNamBranch ? "EARLY CHECK OUT: Informed prior to 15:00 of new check out date" : "",
            bookingRule5_1: isVietNamBranch ? "": "",
            bookingRule5_2: isVietNamBranch ? "Less than 24 hours , 100% charged" :"",
            bookingRule5_3: isVietNamBranch ? "Less than 48 hours , 50% charged": "",
            bookingRule6: "Please be noted:",
            bookingRule6_1: "This Booking Confirmation should be showed to Reception upon Check-in",
            bookingRule6_2: isVietNamBranch ?
                           "The valid Passport ( non Vietnamese ) or ID (Vietnamese ) is required for guest registration as Vietnamese laws":
                           "The valid Passport ( non Cambodia ) or ID ( Cambodia ) is required for guest registration as Cambodia laws",
         //   bookingRule6_3: isVietNamBranch ? "VAT INVOICE can be issued during stay. If you need VAT INVOICE, please let us know before check out date.":"",
            bookingRule6_3: isVietNamBranch ? "According to the regulations of the Ministry of Finance of Vietnam, VAT INVOICE for accommodation can only be issued during your stay. " : "",
            bookingRule6_4: isVietNamBranch ? "If you need VAT INVOICE, please let us know until check in date. ":"",
            bookingRule6_5: isVietNamBranch ? "We will send you E-VAT invoice by email. " : "",
            bookingRule6_6: isVietNamBranch ? "※We cannot issue VAT after checkout due to the regulation" : "",

            // bookingRule6_4: isVietNamBranch ? "If check out date is Sat, Sun and holiday, VAT INVOICE will be issued and sent on the next business day.":"",
            localMap: "【 LOCATION MAP 】",
            receptionRegards: "Warmest regards",
            receptionSign: "Receptionist Signature:",

            tel :"Tel",
            email: "Email",
            guestSign: "Customer’s Signature:",
            footer: "Thank you very much for choosing to stay with Azumaya Hotel. We are looking forward to welcoming you!",
        }
    }

    useEffect(() => {
        document.title = `Az_${coverNameToID(Number(branchID))}_Booking confirmation for `;

        const loadData = async (token) => {
            dispatch(unitsAction.showLoading());
            const result = await bookingApi.takeBookingDataByIdWithType(branchID, idRoom, type ? type :"sign",  token);
            // console.log("result: " ,result)
            const branchInformation = await branchApi.findElementBranch(branchID, "booking", token);
            const { listGuestSelect, listSchedule } = result.data.data;
            var guestName = "";

            listGuestSelect.forEach((item, index) => {
                guestName = guestName + (index > 0 ? " & " : "") + (item.gender ? "Ms." : "Mr.") + item.name
            });

            let totalList = 0;
            listSchedule.forEach(item => {
                totalList = Number(item.price) * Number(item.nights);
            });

            if (result.data.data2.rentCarList.length > 0) {
               // data.otherServer = "RentCar fee"
              //  data.priceOtherServer = result.data.data2.princeTotalRentCar

                data.priceRentCar = result.data.data2.princeTotalRentCar
            }

            // const priceTotal = totalList + (Number(data.priceLateOut) + Number(data.priceEarlyCheckIn)
            //     + Number(data.pricePickUp) + Number(data.priceDropOff) + Number(data.priceOtherServer))

            function checkMarginTopBottom() {
                if (result.data.data.listSchedule.length === 1) {
                    return 190
                } else if (result.data.data.listSchedule.length === 2 || result.data.data.listSchedule.length === 3) {
                    return 140
                } else if (result.data.data.listSchedule.length === 4) {
                    return 110
                } else {
                    return 80
                }
            }

            document.title = `Az ${coverNameToID(Number(branchID))}_Booking confirmation for ${guestName} (${changeDataToDDMMYYYY(listSchedule[0].checkin).slice(0, 2)}-${changeDataToDDMMYYYY(listSchedule[0].checkout)})`;
            dispatch(unitsAction.hiddenLoading());

            // check time picktup / dropoff

            const  hoursPickUp = result.data.data2.pickUp.time.length === 4 ? result.data.data2.pickUp.time.slice(0,1) : result.data.data2.pickUp.time.slice(0,2);
            const  minutePickUp = result.data.data2.pickUp.time.slice(3,5);

            const  hoursDropOff = result.data.data2.dropOff.time.length === 4 ? result.data.data2.dropOff.time.slice(0,1) : result.data.data2.dropOff.time.slice(0,2);
            const  minuteDropOff = result.data.data2.dropOff.time.length === 4 ? result.data.data2.dropOff.time.slice(2,5) : result.data.data2.dropOff.time.slice(3,5);

            function checkLateOut() {
                // check all schedule late out

                function cacalTimeout(data) {
                    const price = changeStringVNDtoNumber(data.price)
                    
                    
                    if(data.timeLateOut === "12:00") return 0;
                    if(Number(data.timeLateOut.slice(0, 2)) === 18) {
                        console.log("price", Math.round((price / 2) * 100))
                        if(data.timeLateOut === "18:00") return Math.round((price / 2) * 100) / 100;
                        else return price
                    }
                    if(Number(data.timeLateOut.slice(0, 2)) > 18) return price;
                    if(Number(data.timeLateOut.slice(0, 2)) < 18) return Math.round((price / 2) * 100) / 100;
                }

                if (listSchedule.length > 1) {
                    let totalLateOut = 0;

                    listSchedule.forEach(item => {
                        totalLateOut += cacalTimeout(item)
                    });

                    return totalLateOut;
                } else return cacalTimeout(listSchedule[0])

            }

            function checkTimeLateOut(type) {
                // type = hour , minute
                if(listSchedule.length ===0) {
                    if(type === 'hours') return listSchedule[0].timeLateOut === "12:00" ? "00" : listSchedule[0].timeLateOut.slice(0, 2);
                    else return listSchedule[0].timeLateOut === "12:00" ? "00" : listSchedule[0].timeLateOut.slice(3, 5);
                } else {
                    let timeLateOutHours = "00";
                    let timeLateOutMinute = "00";

                    listSchedule.forEach(item => {
                        if(type === 'hours' && item.timeLateOut !== "12:00") {
                            timeLateOutHours =  item.timeLateOut.slice(0, 2);
                        } 
                        
                        if (type === 'minutes' && item.timeLateOut !== "12:00") {
                            timeLateOutMinute = item.timeLateOut.slice(3, 5);  
                        } 
                    })

                    if(type==="hours") return timeLateOutHours;
                    else return timeLateOutMinute;
                }
            }

            //result.data.data.listSchedule auto sort
            const sortListSchedule = result.data.data.listSchedule.sort((a, b) => {
                if (new Date(a.checkin) > new Date(b.checkin)) return 1;
                else if (new Date(a.checkin) < new Date(b.checkin)) return -1;
                else return 0;
            }).map(item => {
                item.roomType = checkRoomType(item.roomSelect, branchInformation.data.data.listRoom,);
                return item;
            })

            const newData = {
                ...data,
                name: guestName,
                rateYen: branchInformation.data.data.hotelInvoice.rateYen,
                company_contract: result.data.data.company_contract,
                company_name: listGuestSelect[0].company_name,
                // priceTotal: priceTotal,
                branchName: branchInformation.data.data.name,
                listRoom: branchInformation.data.data.listRoom,
                numberGuest: listGuestSelect.length,
                email: result.data.data.mail,
                request1: result.data.data.request1,
                listSchedule: sortListSchedule ,//result.data.data.listSchedule,
                payment: result.data.data.payment,
                smoking: listGuestSelect[0].special.smoking,
                high: listGuestSelect[0].special.high,
                vat: result.data.data2.vat,
                // service BOOKING SUMMARY
                lateOutHour: checkTimeLateOut("hours"),
                lateOutMinute: checkTimeLateOut("minutes"),
                earlyCheckInHour: listSchedule[0].timeEarly === "15:00" ? "00" : 
                       Number(listSchedule[0].timeEarly.slice(0,2)) >= 15 ? "00" : listSchedule[0].timeEarly.slice(0, 2),
                earlyCheckInMinute: listSchedule[0].timeEarly === "15:00" ? "00" : 
                       Number(listSchedule[0].timeEarly.slice(0,2)) >= 15 ? "00" :  listSchedule[0].timeEarly.slice(3, 5),
                priceLateOut: checkLateOut(),
                priceEarlyCheckIn: Number(listSchedule[0].timeEarly.slice(0,2)) >= 15 ? 0 :
                                  (( Number(listSchedule[0].timeEarly.slice(0,2)) >= 10 && Number(listSchedule[0].timeEarly.slice(0,2)) < 15) || listSchedule[0].timeEarly === "10:00")  ? Math.round((listSchedule[0].price / 2) * 100 ) / 100
                                  : listSchedule[0].price,
                pricePickUp: result.data.data2.pickUp.price,
                flightPickUp: result.data.data2.pickUp.flight,
                hoursPickUp,
                minutePickUp,

                priceDropOff: result.data.data2.dropOff.price,
                flightDropOff: result.data.data2.dropOff.flight,
                hoursDropOff,
                minuteDropOff,
                marginTopBottom: checkMarginTopBottom(),
                maker: result.data.data2.maker,
            }

            const pickUpPriceOnServer = result.data.data2.pickUp.price;
            const dropOffPriceOnServer = result.data.data2.dropOff.price;
            const rentCarPriceOnServer = result.data.data2.princeTotalRentCar;
            const rateYenOnServer = branchInformation.data.data.hotelInvoice.rateYen;

            function checkPriceIsZeroChangeToYen(data) {
                return Number(data) === 0 ? 0 : Math.round((changeStringVNDtoNumber(data))/Number(rateYenOnServer))
            }

            const newDefualPrice  = {...defaultPrice};

            newDefualPrice.service = {
                lateOutUSD: changeStringVNDtoNumber(newData.priceLateOut) / 25000,
                lateOutVND: newData.priceLateOut,
                lateOutYEN: checkPriceIsZeroChangeToYen(newData.priceLateOut),

                earlyCheckInUSD: newData.priceEarlyCheckIn / 25000,
                earlyCheckInVND: newData.priceEarlyCheckIn ,
                earlyCheckInYEN: checkPriceIsZeroChangeToYen(newData.priceEarlyCheckIn),

                pickUpUSD: changeStringVNDtoNumber(pickUpPriceOnServer) / 25000,
                pickUpVND: pickUpPriceOnServer,
                pickUpYEN: checkPriceIsZeroChangeToYen(pickUpPriceOnServer),

                dropOffUSD: changeStringVNDtoNumber(dropOffPriceOnServer) / 25000,
                dropOffVND: dropOffPriceOnServer ,
                dropOffYEN: checkPriceIsZeroChangeToYen(dropOffPriceOnServer),

                rentCarUSD: changeStringVNDtoNumber(rentCarPriceOnServer) / 25000,
                rentCarVND: rentCarPriceOnServer ,
                rentCarYEN: checkPriceIsZeroChangeToYen(rentCarPriceOnServer),

                otherServerUSD : data.priceOtherServer / 25000,
                otherServerVND : data.priceOtherServer ,
                otherServerYEN : checkPriceIsZeroChangeToYen(data.priceOtherServer)
            }

            newDefualPrice.listSchedule = [];

            newData.listSchedule.forEach((shchedule, index) => {
                if (changeStringVNDtoNumber(shchedule.price) ) {
                    newData.listSchedule[index]["price"] = changeStringVNDtoNumber(shchedule.price)  ;//* 25000;
                    newDefualPrice.listSchedule.push({
                        priceUSD:  changeStringVNDtoNumber(shchedule.price) / 25000,
                        priceVND:  changeStringVNDtoNumber(shchedule.price),
                        priceYEN: checkPriceIsZeroChangeToYen(shchedule.price),
                    });
                }
            });

            setDefaultPrice(newDefualPrice);

            // newData["priceLateOut"] = newData["priceLateOut"];
            // newData["priceEarlyCheckIn"] = newData["priceEarlyCheckIn"];
            // newData["pricePickUp"] = newData["pricePickUp"];
            // newData["priceDropOff"] = newData["priceDropOff"];
            // newData["priceOtherServer"] = newData["priceOtherServer"];

            let totalList2 = 0;

            newData.listSchedule.forEach(item => {
                totalList2 = totalList2 + changeStringVNDtoNumber(item.price) * Number(item.nights);
            })
    
            newData.priceTotal = Math.round((totalList2 + (changeStringVNDtoNumber(newData.priceLateOut) + changeStringVNDtoNumber(newData.priceEarlyCheckIn)
                + changeStringVNDtoNumber(newData.pricePickUp) + changeStringVNDtoNumber(newData.priceDropOff)
                + changeStringVNDtoNumber(newData.priceRentCar)
                + changeStringVNDtoNumber(newData.priceOtherServer))) * 100) / 100

            setData(newData);

        }

        if (token) {
            loadData(token)
        }
    }, [token])

    window.onbeforeprint = function(){
        window.scrollTo(0, 0);
     }

     function checkRoomType(room, listRoom) {
        let typeSelect = "";
        const listKeyOnListRoom = Object.keys(listRoom);
        if (listKeyOnListRoom.length > 0) {
            listKeyOnListRoom.forEach(type => {
                if (listRoom[type].includes(room)) {
                    typeSelect = type
                }
            })
        }
        return typeSelect.slice(0, typeSelect.length - 7).toLocaleUpperCase();
    }

    function renderScheduleList() {
        var result = [];

        // function checkRoomType(room) {
        //     var typeSelect = "";
        //     const listKeyOnListRoom = Object.keys(data.listRoom);
        //     if (listKeyOnListRoom.length > 0) {
        //         listKeyOnListRoom.forEach(type => {
        //             if (data.listRoom[type].includes(room)) {
        //                 typeSelect = type
        //             }
        //         })
        //     }
        //     return typeSelect.slice(0, typeSelect.length - 7).toLocaleUpperCase();
        // }

        if (data.listSchedule.length > 0) {
            data.listSchedule.forEach((schedule, index) => {
                result.push(<tr className="tr-details">
                    <td className="col1">
                        <span className="ng-binding">{index + 1}</span>
                    </td>
                    <td className="col3">
                        <span className="ng-binding">{changeDataToDDMMYYYY(schedule.checkin)}</span>
                    </td>
                    <td className="col4">
                        <span className="ng-binding">{changeDataToDDMMYYYY(schedule.checkout)}</span>
                    </td>
                    <td className="col5">
                        <span className="ng-binding">{schedule.nights}</span>
                    </td>
                    <td className="col6">
                        <span>
                            <input
                                style={{ width: '50px', border: '0px', textAlign: 'center', fontWeight: "600" }}
                                type="text" ng-blur="blur()"
                                ng-model="bill.numofroom"
                                onChange={e => onChangeData(e.target.value, "numberGuest", index)}
                                className="currency-input ng-pristine ng-untouched ng-valid ng-not-empty"
                                value={data.numberGuest} />
                        </span>
                    </td>
                    <td className="col7" colspan="2">
                    <span>
                            <input
                                // style={{ width: '50px', border: '0px', textAlign: 'center', fontWeight: "600" }}
                                style={{width:'100%',  textAlign: 'center', fontWeight: "600", border: 'unset'}}
                                type="text" ng-blur="blur()"
                                ng-model="bill.numofroom"
                                onChange={e => onChangeData(e.target.value, "roomType", index)}
                                className="currency-input ng-pristine ng-untouched ng-valid ng-not-empty"
                                value={schedule.roomType} />
                        </span>
                        {/* <span className="ng-binding">{checkRoomType(schedule.roomSelect)}</span> */}
                    </td>
                    <td className="col8">
                       <span ng-show="usdShow" className={data.currency === "yen" ? "cur_usd ng-binding" : "op-hidden"}
                            style={{fontSize:'1.5rem'}}>¥</span>
                        <span ng-show="usdShow" className={data.currency === "$" ? "cur_usd ng-binding" : "op-hidden ng-binding"}>
                            {data.currency}</span>
                        <NumberFormat
                            type="text"
                            thousandSeparator={true}
                            className="currency-input price ng-pristine ng-untouched ng-valid ng-not-empty"
                            style={{
                                width: data.currency === "VND" ? '90px' : '70px',
                                border: '0px',
                                textAlign: 'center',
                            }}
                            onChange={e => onChangeData(e.target.value, "price", index)}
                            value={schedule.price}
                        /> {data.vatPlus ? "+" : ""}
                        <span ng-show="usdShow" className={data.currency === "VND" ? "cur_usd ng-binding" : "op-hidden"}
                            style={{fontSize:'1.5rem'}}>
                            {data.currency}</span>
                    </td>
                </tr>)
            })

        }
        return result;
    }

    function onChangeData(value, target, option) {
        const newData = { ...data };
        switch (target) {
            case "price":
                newData.listSchedule[option][target] = value;
                break;
            case "priceLateOut": case "priceEarlyCheckIn": case "pricePickUp": case "priceDropOff": case "priceOtherServer": {
                newData[target] = value;
                break;
            }
            case "currency": {
                newData[target] = value;
                newData.listSchedule.forEach((shchedule, index) => {
                    if (value === "VND") {
                        if (newData.vatPlus === true)  newData.listSchedule[index]["price"] = defaultPrice.listSchedule[index].priceVND;
                        else newData.listSchedule[index]["price"] = Math.round((
                            changeStringVNDtoNumber(defaultPrice.listSchedule[index].priceVND) * data.vatRate) * 100) / 100
                    } else if (value === "$") {
                        if (newData.vatPlus === true)  newData.listSchedule[index]["price"] = defaultPrice.listSchedule[index].priceUSD ;
                        else newData.listSchedule[index]["price"] = Math.round((
                            changeStringVNDtoNumber(defaultPrice.listSchedule[index].priceUSD) * data.vatRate) * 100) / 100
                    } else {
                        if (newData.vatPlus === true)  newData.listSchedule[index]["price"] = defaultPrice.listSchedule[index].priceYEN;
                        else newData.listSchedule[index]["price"] = Math.round((defaultPrice.listSchedule[index].priceYEN * data.vatRate) * 100) / 100
                    }
                });

                if (value === "VND") {
                    newData["priceLateOut"] = defaultPrice.service.lateOutVND;
                    newData["priceEarlyCheckIn"] = defaultPrice.service.earlyCheckInVND;
                    newData["pricePickUp"] = defaultPrice.service.pickUpVND;
                    newData["priceDropOff"] = defaultPrice.service.dropOffVND;
                    newData["priceRentCar"] = defaultPrice.service.rentCarVND;
                    newData["priceOtherServer"] = defaultPrice.service.otherServerVND;
                } else if (value === "$") {
                    newData["priceLateOut"] = defaultPrice.service.lateOutUSD;
                    newData["priceEarlyCheckIn"] = defaultPrice.service.earlyCheckInUSD;
                    newData["pricePickUp"] = defaultPrice.service.pickUpUSD;
                    newData["priceDropOff"] = defaultPrice.service.dropOffUSD;
                    newData["priceRentCar"] = defaultPrice.service.rentCarUSD;
                    newData["priceOtherServer"] = defaultPrice.service.otherServerUSD;
                } else {
                    newData["priceLateOut"] = defaultPrice.service.lateOutYEN;
                    newData["priceEarlyCheckIn"] = defaultPrice.service.earlyCheckInYEN;
                    newData["pricePickUp"] = defaultPrice.service.pickUpYEN;
                    newData["priceDropOff"] = defaultPrice.service.dropOffYEN;
                    newData["priceRentCar"] = defaultPrice.service.rentCarYEN;
                    newData["priceOtherServer"] = defaultPrice.service.otherServerYEN;
                }
              //  warningToast("Reset all user change")
                break;
            }
            case "vatPlus": {
                newData[target] = value;
                newData.listSchedule.forEach((shchedule, index) => {
                    if (value === false) {
                        newData.listSchedule[index]["price"] = Math.round((changeStringVNDtoNumber(shchedule.price) * data.vatRate) * 100) / 100
                    } else {
                        newData.listSchedule[index]["price"] = Math.round((changeStringVNDtoNumber(shchedule.price) / data.vatRate) * 100) / 100
                    }
                });

                if (value === false) {
                    newData.priceLateOut = Math.round((changeStringVNDtoNumber(newData.priceLateOut) * data.vatRate) * 100) / 100;
                    newData.priceEarlyCheckIn = Math.round((changeStringVNDtoNumber(newData.priceEarlyCheckIn) * data.vatRate) * 100) / 100;
                } else {
                    newData.priceLateOut = Math.round((changeStringVNDtoNumber(newData.priceLateOut) / data.vatRate) * 100) / 100;
                    newData.priceEarlyCheckIn = Math.round((changeStringVNDtoNumber(newData.priceEarlyCheckIn) / data.vatRate) * 100) / 100;
                }

                break;
            }
            case "vatRate": {
                newData[target] = Number(value);
                newData.listSchedule.forEach((shchedule, index) => {
                    if (newData.vatPlus === false) {
                        const oldRate = Math.round((changeStringVNDtoNumber(shchedule.price) / Number(data.vatRate)) * 100) / 100
                        newData.listSchedule[index]["price"] = Math.round((oldRate * Number(value)) * 100) / 100
                    }
                });

                if (newData.vatPlus === false) {
                    const oldPriceEarlyCheckIn = Math.round((changeStringVNDtoNumber(newData.priceEarlyCheckIn) / Number(data.vatRate)) * 100) / 100;
                    newData.priceEarlyCheckIn = Math.round((oldPriceEarlyCheckIn * Number(value)) * 100) / 100;

                    const oldPriceLateOut = Math.round((changeStringVNDtoNumber(newData.priceLateOut) / Number(data.vatRate)) * 100) / 100;
                    newData.priceLateOut =  Math.round((oldPriceLateOut * Number(value)) * 100) / 100//Math.round((oldPriceLateOut / Number(value)) * 100) / 100;
                } else {
             //       newData.priceLateOut = Math.round((changeStringVNDtoNumber(newData.priceLateOut) / Number(value)) * 100) / 100;
              //      newData.priceEarlyCheckIn = Math.round((changeStringVNDtoNumber(newData.priceEarlyCheckIn) / Number(value)) * 100) / 100;
                }

                break;
            }
            case "smoking": case "noSmoking": {
                if((Number(branchID) === 11 || Number(branchID) === 97) ) {
                    if(newData[target] === undefined) {
                        newData[target] = value;
                    } else {
                        newData["smoking"] = undefined;
                        newData["noSmoking"] = undefined;
                    };
                } else {
                    newData[target] = value;
                }
                break;
            }
            case "roomType": 
                newData.listSchedule[option][target] = value;
                break;
            default:
                newData[target] = value;
                break;
        }

        var totalList = 0;

        newData.listSchedule.forEach(item => {
            totalList = totalList + changeStringVNDtoNumber(item.price) * Number(item.nights);
        })

        newData.priceTotal = Math.round((totalList + (changeStringVNDtoNumber(newData.priceLateOut) + changeStringVNDtoNumber(newData.priceEarlyCheckIn)
            + changeStringVNDtoNumber(newData.pricePickUp) + changeStringVNDtoNumber(newData.priceDropOff)
            + changeStringVNDtoNumber(newData.priceRentCar)
            + changeStringVNDtoNumber(newData.priceOtherServer))) * 100) / 100;

        setData(newData);
    }

    function updateRateYenToServer(value) {
        const update = async () => {
            try {
                const result = await branchApi.updateExChangeRate(branchID, { value }, token);
                if (result) return informationToast("" + result.data.msg);
                else errorToast("Error when update rate");
            } catch (error) {
                errorToast("Error when update rate")
            }
        }
        update();
    }

    return (
        <>
      { token && <div className="d-flex">
            <div id="mainContain" className="container-fluid" style={{ width: '1100px', }}>
                <div className="col-md-12 col-lg-12 col-sm-12 d-flex no-printer"
                    style={{ width: '1070px', position: 'fixed', background: 'aqua', zIndex: '99', padding: '6px' }}>
                    <div className="radio">
                        <label className="language">
                            <input type="radio" name="language" className=""
                                onClick={() => onChangeData("eng", "langueage")}
                                id="english" ng-click="translate('en')" checked={data.langueage === "eng"} />
                            <strong> English </strong>
                        </label>

                        <label className="language ml-3">
                            <input type="radio" name="language" className=""
                                checked={data.langueage === "jap"}
                                onClick={() => onChangeData("jap", "langueage")}
                                ng-click="translate('jp')" id="japanese" />
                            <strong> Japanese </strong>
                        </label>

                        {
                            isVietNamBranch ? <label className="language ml-3">
                            <input type="radio" name="language" className=""
                                checked={data.langueage === "viet"}
                                onClick={() => onChangeData("viet", "langueage")}
                                ng-click="translate('vn')" id="vietnamese" />
                            <strong> Vietnamese </strong>
                        </label> :""
                        }

                        
                    </div>
                    <div className="ml-2">|</div>
                    <div className="radio" style={{width:isVietNamBranch ?'190px' : '200px'}}>
                        {
                            isVietNamBranch ? <label className="language">
                            <strong>Currency:</strong>
                            <select id="currency"
                                onChange={e => onChangeData(e.target.value, "currency")}>
                                <option value="VND">VND (vnd)</option>
                                <option value="$">USD ($)</option>
                                <option value="yen">YEN (¥)</option>
                            </select>      
                        </label> : <label className="language ml-5">AZUMAYA PHNOM PENH</label>
                        }
                        
                       {
                        data.currency ==="yen" &&  <div style={{fontWeight:'600'}}>
                            Rate Yen:
                            <input style={{width:'50px'}}
                                value={data.rateYen}
                                type="number"
                                onChange = {e=> onChangeData(e.target.value,"rateYen")}
                                onBlur={e => updateRateYenToServer(e.target.value)}
                            />
                        </div>
                       } 
                    </div>
                    <div className="ml-2 mr-4">|</div>
                    <div className="radio d-flex" style={{width:"250px"}}>
                        <label className="vat-plus ">
                            <input type="radio" name="vat-plus" className=""
                                onClick={data.vatPlus ? "" : () => onChangeData(true, "vatPlus")}
                                checked={data.vatPlus} />
                            {/* <strong > Excluded VAT (add +) </strong> */}
                            <strong > Excluded VAT</strong>

                        </label>

                        <label className="vat-plus ml-3">
                            <input type="radio" name="vat-plus" className=""
                                checked={!data.vatPlus}
                                onClick={!data.vatPlus ? "" : () => onChangeData(false, "vatPlus")}
                            />
                            {/* <strong className="red-color"> Included VAT (clear+)</strong> */}
                            <strong className="red-color"> Included VAT</strong>
                        </label>
                    </div>
                    {/* <div className="radio">
                        <label className="language ml-5">
                            <strong>VAT +:</strong>
                            <select id="VAT"
                                onChange={e => onChangeData(e.target.value, "vatPlus")}>
                                <option value={true}>Excluded VAT</option>
                                <option value={false}>Included VAT</option>
                            </select>      
                        </label> 
                    </div> */}

                    <div className="radio">
                        {
                            isVietNamBranch ? <label className="language ml-5">
                            <strong>VAT rate:</strong>
                            <select id="VAT"
                                onChange={e => onChangeData(e.target.value, "vatRate")}>
                                <option value={1 + RATE_VAT}>{`${RATE_VAT*100}%`}</option>
                                <option value="1.1">10%</option>
                            </select>      
                        </label> : <label className="language ml-5"></label>
                        }
                        
                       {
                        data.currency ==="yen" &&  <div style={{fontWeight:'600'}}>
                            Rate Yen:
                            <input style={{width:'50px'}}
                                value={data.rateYen}
                                type="number"
                                onChange = {e=> onChangeData(e.target.value,"rateYen")}
                                onBlur={e => updateRateYenToServer(e.target.value)}
                            />
                        </div>
                       } 
                    </div>

                    <button className="btn btn-primary"
                        style={{
                            width: '120px',
                            height: '30px',
                            marginLeft: '24px',
                            textAlign: 'center',
                            alignItems: 'center',
                            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
                        }}
                        onClick={() => {
                            window.scrollTo(0, 0);
                            window.print();
                        }}
                    >PRINT / SAVE PDF</button>
                </div>
                <div className="content print" style={{ marginTop: '55px' }}>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12"
                            style={{ padding: '0' }}>
                            <table className="tbl-header">
                                <tbody >
                                    <tr className='d-flex justify-content-between align-items-center'>
                                        <td className="col1">
                                            <div style={{ width: '100%', }} align="right">
                                                    <img 
                                                       src="https://database.azumayareport.com//img/logo/logo_az.png" 
                                                       alt="logo." 
                                                       className="img-responsive logo" 
                                                       style={{ width: '170px' }} 
                                                    />
                                            </div>
                                        </td>
                                        <td className="col2">
                                            <div className="bk-title">
                                                <span className="ng-binding" style={{ fontSize: '2.5rem' }}>{LANGUAGE[data.langueage].title}</span><br />
                                                <span className="ng-binding" style={{ fontWeight: '700' }}>{"[ " + data.branchName + " ]"}</span>
                                            </div>
                                        </td>
                                        <td className="col3">
                                            <div>
                                                <input
                                                    type="text"
                                                    name=""
                                                    value={data.roomSelect}
                                                    onChange={e => onChangeData(e.target.value, "roomSelect")}
                                                    placeholder=""
                                                    style={{
                                                        textAlign: "center",
                                                        fontSize: '2rem',
                                                        fontWeight: '600',
                                                        width: '200px',
                                                        margin: '0 10px',
                                                        padding: '10px',
                                                        border: '2px #000 solid',
                                                    }}

                                                /></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12"
                            style={{
                                padding: '0',
                                border: '3px solid black',
                            }}>
                            <table className="rbk-detail">
                                <tbody className="rbk-detail-head">
                                    <tr>
                                        <td colspan="9" className="white-color ng-binding" style={{ fontSize: '1.4rem' }}>{LANGUAGE[data.langueage].content}</td>
                                    </tr>
                                </tbody>

                                <tbody>
                                    <tr style={{ textAlign: 'left' }}>
                                        <td colspan="9" className="td-bk-detail" style={{ padding: "10px 16px", border: '1px solid white' }}>
                                            <div className='d-flex'>
                                                <span className="ng-binding ml-4 mr-2">{LANGUAGE[data.langueage].guestName}</span>
                                                <input type="text"
                                                    style={{ width: '84%', fontWeight: "700", border: '0px', borderBottom: '0.8px solid black', paddingLeft: '12px' }}
                                                    onChange={e => onChangeData(e.target.value, "name")}
                                                    value={data.name.toLocaleUpperCase()}
                                                    className="name-bold" />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr style={{ textAlign: 'left', }}>
                                        <td colspan="9" className="td-bk-detail" style={{ padding: "10px 16px", border: '1px solid white' }}>
                                            <span className="ng-binding ml-4 mr-2">{LANGUAGE[data.langueage].companyName}</span>
                                            <input type="text"
                                                style={{ width: '70%', border: '0px', borderBottom: '0.8px solid black', paddingLeft: '12px' }}
                                                value={data.company_name}
                                                onChange={e => onChangeData(e.target.value, "company_name")} />
                                            {/* <span className="ng-binding ml-4 mr-2" >{LANGUAGE[data.langueage].contract}</span>  */}
                                            <span className="ng-binding">
                                                {LANGUAGE[data.langueage].contract}
                                                <input type="checkbox" name="" value="1"
                                                    onChange={""}
                                                    checked={data.company_contract}
                                                    style={{ width: 'auto', margin: '0 2px', position: 'relative', top: '2px', }} disabled={!data.company_contract} />
                                                {LANGUAGE[data.langueage].yes}
                                                <input type="checkbox" name=""
                                                    onChange={""}
                                                    style={{ width: 'auto', position: 'relative', top: '2px', margin: '0 2px' }}
                                                    disabled={data.company_contract} checked={!data.company_contract} />{LANGUAGE[data.langueage].no}</span>
                                        </td>
                                    </tr>
                                    <tr style={{ textAlign: 'left' }}>
                                        <td colspan="9" className="td-bk-detail" style={{ padding: "10px 16px", border: 'none' }}>
                                            <span className="ng-binding ml-4 mr-2" >{LANGUAGE[data.langueage].mailAddress}</span>
                                            <input
                                                type="text"
                                                style={{ width: '85%', border: '0px', borderBottom: '0.8px solid black' }}
                                                onChange={e => onChangeData(e.target.value, "email")}
                                                value={data.email}
                                            />
                                        </td>
                                    </tr>
                                </tbody>

                                <tbody className="rbk-detail-body">
                                    <tr className="tr-title">
                                        <td className="col1" rowspan="2"></td>
                                        <td className="col3" rowspan="2" style={{ width: '160px' }}>
                                            <span className="ng-binding">{LANGUAGE[data.langueage].checkin}</span>
                                        </td>
                                        <td className="col4" rowspan="2" style={{ width: '160px' }}>
                                            <span className="ng-binding">{LANGUAGE[data.langueage].checkout}</span>
                                        </td>
                                        <td className="col5" rowspan="2">
                                            <span className="ng-binding">{LANGUAGE[data.langueage].nights}</span>
                                        </td>
                                        <td className="col6" rowspan="2" style={{ width: '75px' }}>
                                            <span className="ng-binding">{LANGUAGE[data.langueage].guestNumber}</span>
                                        </td>
                                        <td className="col7" rowspan="2" colspan="2">
                                            <span className="ng-binding">{LANGUAGE[data.langueage].roomType}</span>
                                        </td>
                                        <td className="col8" colspan="2">
                                            <span className="ng-binding">{LANGUAGE[data.langueage].roomRate}</span>
                                        </td>
                                    </tr>
                                    <tr className="tr-title"></tr>
                                </tbody>

                                <tbody className="rbk-detail-body ng-scope" ng-repeat="bill in bills" ng-init="setTotals(bill)">
                                    <tr className="tr-title"></tr>
                                    <tr className="tr-title"></tr>
                                    {renderScheduleList()}

                                    <tr className="spe-req">
                                        <td colspan="9">
                                            <div className="mb-1 mt-1">
                                                <span className="ng-binding" style={{ textDecoration: 'underline', fontSize:'1.2rem' }}>{LANGUAGE[data.langueage].request}</span>
                                            </div>
                                            <div>
                                                <div align="left" style={{ marginLeft: '100px', display: 'flex' }}>
                                                    <input id="smoking" className="smoking" type="checkbox" checked={data.smoking && data.smoking !== undefined}
                                                        onChange={() => onChangeData(true, "smoking")} />
                                                    <label for="smoking" className="ng-binding ml-2" style={{ width: '150px',fontSize:'1.3rem' }}
                                                        onChange={() => onChangeData(true, "smoking")}>{LANGUAGE[data.langueage].smoking}</label>
                                                    <input id="nonsmoking" className="nonsmoking" type="checkbox" checked={!data.smoking && data.smoking !== undefined}
                                                        onChange={() => onChangeData(false, "smoking")} />
                                                    <label for="nonsmoking" className="ng-binding ml-2" style={{ width: '150px',fontSize:'1.3rem' }}
                                                        onChange={() => onChangeData(false, "smoking")}>{LANGUAGE[data.langueage].noSmoking}</label>
                                                    <input id="hightfloor" type="checkbox" checked={data.high}
                                                        onChange={() => onChangeData(!data.high, "high")} />
                                                    <label for="hightfloor" className="ng-binding ml-2" style={{ width: '150px',fontSize:'1.3rem' }}
                                                        onChange={() => onChangeData(!data.high, "high")}>{LANGUAGE[data.langueage].highFloor}</label>
                                                    <input type="checkbox" checked={data.vat}
                                                           onChange={() => onChangeData(!data.vat, "vat")} />
                                                    <label className="red-color ng-binding" style={{ width: '150px',fontSize:'1.3rem' }}>
                                                        {LANGUAGE[data.langueage].VAT}</label>
                                                </div>
                                                <div style={{ marginLeft: '100px', textAlign: 'left', marginBottom: '2px',display:'flex'}}>
                                                    <label for="other" style={{ marginRight: '5px', fontSize: '1.3rem' }} className="ng-binding">
                                                    {LANGUAGE[data.langueage].otherRequest}
                                                    </label>
                                                    <textarea cols="110" rows="2"
                                                        value={data.request1}
                                                        style={{ verticalAlign: 'middle', fontSize: '14px', padding: '2px', border: '0.5px solid black'}}
                                                        onChange={e => onChangeData(e.target.value, "request1")}
                                                        className="ng-binding"></textarea>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody className="rbk-detail-head">
                                    <tr>
                                        <td colspan="9">
                                            <span className="white-color ng-binding"
                                                style={{ fontSize: '1.4rem' }}>
                                                {LANGUAGE[data.langueage].summary}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>

                                {/* <tbody className="rbk-detail-head-title" style={{ height: '40px' }}>
                                    <tr><td colspan="9">
                                        <span></span>
                                    </td></tr>
                                </tbody> */}

                                <tbody className="rbk-detail-summary">
                                    <tr>
                                        <td className="col1" colspan="2" rowspan="2">
                                            <span className="ng-binding">{LANGUAGE[data.langueage].bookingService}</span>
                                        </td>
                                        <td className="col3" colspan="5">
                                            <span className="ng-binding">{LANGUAGE[data.langueage].lateCheckOut}</span>
                                            <input type="text" className="bk_sumary" maxlength="2"
                                                value={data.lateOutHour}
                                                onChange={e => onChangeData(e.target.value, "lateOutHour")}
                                                style={{ border: '0px', borderBottom: '1px solid black', width: '50px', textAlign: 'center' }} />:
                                            <input type="text" maxlength="2"
                                                value={data.lateOutMinute}
                                                onChange={e => onChangeData(e.target.value, "lateOutMinute")}
                                                style={{ border: '0px', borderBottom: '1px solid black', width: '50px', textAlign: 'center' }}
                                                className="bk_sumary"
                                            />
                                        </td>
                                        <td className="col5" colspan="2">
                                            <label className={data.currency === "$" ? "cur_usd ng-binding" : "op-hidden"}>$</label>
                                            <label className={data.currency === "yen" ? "cur_usd ng-binding" : "op-hidden"} style={{ marginLeft: "7px" }}>¥</label>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                type="text"
                                                name="totalLCOutPrice" min="0"
                                                value={data.priceLateOut}
                                                onChange={e => onChangeData(e.target.value, "priceLateOut")}
                                                style={{ 
                                                    border: '0px', 
                                                    width: data.currency === "VND" ? '100px' : 
                                                                data.currency === "$" ? '56px' : '70px', 
                                                    textAlign: 'center',
                                                    marginLeft: data.currency === "VND" ? '8px' :
                                                                data.currency === "$" ? "7px" : "unset"
                                                }}
                                            />{data.vatPlus ? "+" : ""}
                                            {!waitingAdd && <label className="cur_vnd ng-binding">{data.vatPlus ? "+" : ""}</label>}
                                            <label className={data.currency === "VND" ? "cur_usd ng-binding" : "op-hidden"}>VND</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="col3" colspan="5">
                                            <span className="ng-binding">{LANGUAGE[data.langueage].earlyCheckIn}</span>
                                            <input type="text" className="bk_sumary" maxlength="2"
                                                value={data.earlyCheckInHour}
                                                onChange={e => onChangeData(e.target.value, "earlyCheckInHour")}
                                                style={{ border: '0px', borderBottom: '1px solid black', width: '50px', textAlign: 'center' }} />:
                                            <input type="text" maxlength="2" className="bk_sumary"
                                                value={data.earlyCheckInMinute}
                                                onChange={e => onChangeData(e.target.value, "earlyCheckInMinute")}
                                                style={{ border: '0px', borderBottom: '1px solid black', width: '50px', textAlign: 'center' }} />
                                        </td>
                                        <td className="col8">
                                            <label className={data.currency === "$" ? "cur_usd ng-binding" : "op-hidden"}>$</label>
                                            <label className={data.currency === "yen" ? "cur_usd ng-binding" : "op-hidden"} style={{ marginLeft: "7px" }}>¥</label>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                type="text"
                                                value={data.priceEarlyCheckIn}
                                                onChange={e => onChangeData(e.target.value, "priceEarlyCheckIn")}
                                                style={{ 
                                                    border: '0px', 
                                                    width: data.currency === "VND" ? '100px' :
                                                                data.currency === "$" ? '56px': '70px', 
                                                    textAlign: 'center',
                                                    marginLeft: data.currency === "VND" ? '8px' :
                                                                data.currency === "$" ? "7px" : "unset"
                                                }}
                                            />{data.vatPlus ? "+" : ""}
                                            {!waitingAdd && <label className="cur_vnd ng-binding">{data.vatPlus ? "+" : ""}</label>}
                                            <label className={data.currency === "VND" ? "cur_usd ng-binding" : "op-hidden"}>VND</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="col1" colspan="2=" rowspan="3">
                                            <span className="ng-binding">{LANGUAGE[data.langueage].rentCarService}</span>
                                        </td>
                                        <td className="col3" colspan="5">
                                            <span className="ng-binding">{LANGUAGE[data.langueage].pickUp}</span>
                                            <input type="text" className="bk_sumary" maxlength="2"
                                                value={data.hoursPickUp}
                                                onChange={e => onChangeData(e.target.value, "hoursPickUp")}
                                                style={{ border: '0px', borderBottom: '1px solid black', width: '50px', textAlign: 'center' }} />:
                                            <input type="text" maxlength="2" className="bk_sumary" 
                                                value={data.minutePickUp}
                                                onChange={e => onChangeData(e.target.value, "minutePickUp")}
                                                style={{ border: '0px', borderBottom: '1px solid black', width: '50px', textAlign: 'center' }} /> &nbsp; &nbsp;
                                            <span className="ng-binding">{LANGUAGE[data.langueage].flight}</span>
                                            <input type="text" className="bk_sumary_flight" value={data.flightPickUp}
                                                onChange={e => onChangeData(e.target.value, "flightPickUp")}
                                                style={{ border: '0px', borderBottom: '1px solid black', width: '100px', textAlign: 'center' }} />
                                        </td>
                                        <td className="col5" colspan="2">
                                            <label className={data.currency === "$" ? "cur_usd ng-binding" : "op-hidden"}>$</label>
                                            <label className={data.currency === "yen" ? "cur_usd ng-binding" : "op-hidden"}>¥</label>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                type="text"
                                                name="pickupPrice" min="0"
                                                style={{ border: '0px', width: data.currency === "VND" ? '100px' : '70px', textAlign: 'center' }}
                                                value={data.pricePickUp}
                                                onChange={e => onChangeData(e.target.value, "pricePickUp")}
                                            />
                                            {!waitingAdd &&<label className="cur_vnd ng-binding" style={{color:'white'}}>{data.vatPlus ? "+" : ""}</label>}
                                            <label className={data.currency === "VND" ? "cur_usd ng-binding" : "op-hidden"}>VND</label>
                                        </td>
                                    </tr>
                                    {/* Drop Off */}
                                    <tr>
                                        <td className="col3" colspan="5">
                                            <span className="ng-binding">{LANGUAGE[data.langueage].dropOff}</span>
                                            <input type="text" className="bk_sumary" maxlength="2" 
                                                value={data.hoursDropOff}
                                                onChange={e => onChangeData(e.target.value, "hoursDropOff")}
                                                style={{ border: '0px', borderBottom: '1px solid black', width: '50px', textAlign: 'center' }} />:
                                            <input type="text" maxlength="2" className="bk_sumary" 
                                                value={data.minuteDropOff}
                                                onChange={e => onChangeData(e.target.value, "minuteDropOff")}
                                                style={{ border: '0px', borderBottom: '1px solid black', width: '50px', textAlign: 'center' }} /> &nbsp; &nbsp;
                                            <span className="ng-binding">{LANGUAGE[data.langueage].flight}</span>
                                            <input type="text" className="bk_sumary_flight"
                                                value={data.flightDropOff}
                                                onChange={e => onChangeData(e.target.value, "flightDropOff")}
                                                style={{ border: '0px', borderBottom: '1px solid black', width: '100px', textAlign: 'center' }} />
                                        </td>
                                        <td className="col5" colspan="2">
                                            <label className={data.currency === "$" ? "cur_usd ng-binding" : "op-hidden"}>$</label>
                                            <label className={data.currency === "yen" ? "cur_usd ng-binding" : "op-hidden"}>¥</label>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                type="text"
                                                name="dropoffPrice" min="0"
                                                style={{ border: '0px', width: data.currency === "VND" ? '100px' : '70px', textAlign: 'center' }}
                                                value={data.priceDropOff}
                                                onChange={e => onChangeData(e.target.value, "priceDropOff")}
                                            />
                                            {!waitingAdd &&<label className="cur_vnd ng-binding" style={{color:'white'}}>{data.vatPlus ? "+" : ""}</label>}
                                            <label className={data.currency === "VND" ? "cur_usd ng-binding" : "op-hidden"}>VND</label>
                                        </td>
                                    </tr>
                                    {/* Rent Car */}
                                    <tr>
                                        <td className="col3" colspan="5">
                                            <span className="ng-binding">{LANGUAGE[data.langueage].rentCar}</span>
                                        </td>
                                        <td className="col5" colspan="2">
                                            <label className={data.currency === "$" ? "cur_usd ng-binding" : "op-hidden"}>$</label>
                                            <label className={data.currency === "yen" ? "cur_usd ng-binding" : "op-hidden"}>¥</label>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                type="text"
                                                name="dropoffPrice" min="0"
                                                style={{ border: '0px', width: data.currency === "VND" ? '100px' : '70px', textAlign: 'center' }}
                                                //value={0}
                                                 value={data.priceRentCar}
                                                 onChange={e => onChangeData(e.target.value, "priceRentCar")}
                                            />
                                            {!waitingAdd &&<label className="cur_vnd ng-binding" style={{color:'white'}}>{data.vatPlus ? "+" : ""}</label>}
                                            <label className={data.currency === "VND" ? "cur_usd ng-binding" : "op-hidden"}>VND</label>
                                        </td>
                                    </tr>
                                    {/* Other Service */}
                                    <tr>
                                        <td className="col1" colspan="2">
                                            <span className="ng-binding">{LANGUAGE[data.langueage].otherService}</span>
                                        </td>
                                        <td className="col2" colspan="5">
                                            <textarea
                                                value={data.otherServer}
                                                onChange={e => onChangeData(e.target.value, "otherServer")}
                                                style={{ resize: 'vertical', width: '100%', border: '0px', padding: '2px', minHeight: '8px' }}>
                                            </textarea>
                                        </td>
                                        <td className="col8">
                                            <label className={data.currency === "$" ? "cur_usd ng-binding" : "op-hidden"}>$</label>
                                            <label className={data.currency === "yen" ? "cur_usd ng-binding" : "op-hidden"}>¥</label>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                type="text"
                                                style={{ border: '0px', width: data.currency === "VND" ? '100px' : '70px', textAlign: 'center' }}
                                                value={data.priceOtherServer}
                                                onChange={e => onChangeData(e.target.value, "priceOtherServer")}
                                            />
                                            {!waitingAdd &&<label className="cur_vnd ng-binding" style={{color:'white'}}>{data.vatPlus ? "+" : ""}</label>}
                                            <label className={data.currency === "VND" ? "cur_usd ng-binding" : "op-hidden"}>VND</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="5" style={{ width: '50%', paddingLeft: '5px', paddingRight: '5px', }}>
                                            <div style={{ textAlign: 'left', paddingLeft: '10px', }}>
                                                <span className="ng-binding">{LANGUAGE[data.langueage].include1}</span>
                                            </div>
                                            <div style={{ paddingLeft: '10px' }} className="red-color text-left ng-binding">
                                                {data.vatPlus ? LANGUAGE[data.langueage].include2 : LANGUAGE[data.langueage].include3}
                                            </div>
                                        </td>
                                        <td className="col7" colspan="2">
                                            <span className="red-color ng-binding">
                                                {LANGUAGE[data.langueage].total.toLocaleUpperCase()}
                                            </span>
                                        </td>
                                        <td className="col8">
                                            <label className={data.currency === "$" ? "cur_usd ng-binding" : "op-hidden"}>$</label>
                                            <label className={data.currency === "yen" ? "cur_usd ng-binding" : "op-hidden"}
                                                                style={{marginLeft: '8px'}}>¥</label>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                className="total-number"
                                                type="text"
                                                style={{
                                                    border: '0px',
                                                    width: data.currency === "VND" ? '100px' :
                                                              data.currency === "$" ? '61px': '70px',
                                                    textAlign: 'center'
                                                }}
                                                value={data.priceTotal}
                                                onChange={e => onChangeData(e.target.value, "priceTotal")}
                                            />
                                            <label className="cur_vnd ng-binding">{data.vatPlus ? "+" : ""}</label>
                                            <label className={data.currency === "VND" ? "cur_usd ng-binding" : "op-hidden"}>VND</label>
                                        </td>
                                    </tr>
                                </tbody>

                                <tbody className="rbk-detail-head"><tr><td colspan="9">&nbsp;</td></tr></tbody>

                                <tbody className="rbk-detail-head-title">
                                    <tr>
                                        <td colspan="9">
                                            <div className="checkbox ng-binding">
                                                <span className="ng-binding">{LANGUAGE[data.langueage].payment}</span>
                                                <label className="ng-binding" style={{ width: '100px' }}
                                                    onClick={() => onChangeData("company", "payment")}>
                                                    <input className="mr-2" type="checkbox" checked={data.payment === "company" || data.payment === "pre-pay"} />
                                                    {LANGUAGE[data.langueage].company}</label>
                                                <label className="ng-binding" style={{ width: '100px' }}
                                                    onClick={() => onChangeData("individual", "payment")}>
                                                    <input type="checkbox" className="mr-2" checked={data.payment === "individual"} />
                                                    {LANGUAGE[data.langueage].personal}</label>
                                                <label className="ng-binding" style={{ width: '100px' }}
                                                    onClick={() => onChangeData("cash", "payment")}>
                                                    <input type="checkbox" className="mr-2" checked={data.payment === "cash"} />
                                                    {LANGUAGE[data.langueage].cash}</label>
                                                <label className="ng-binding" style={{ width: '100px' }}
                                                    onClick={() => onChangeData("card", "payment")}>
                                                    <input type="checkbox" className="mr-2" checked={data.payment === "card"} />
                                                    {LANGUAGE[data.langueage].card}</label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12" style={{ padding: '0' }}>
                            <table className="rbk-footer">
                                <tbody style={{ verticalAlign: 'top' }}>
                                    <tr className="col21">
                                        <div ng-show="polEVShow" style={{ border: '1px solid black', textAlign: 'left' }}>
                                            <div className="rh-left">
                                                <span className="ng-binding">{LANGUAGE[data.langueage].bookingRule}</span>
                                            </div>
                                            <div className="rdt-left" style={{ fontSize:'1.2rem', fontWeight:'400' }}>
                                                <ol className="ol1">
                                                    <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule1}<br />
                                                        {LANGUAGE[data.langueage].bookingRule1_1}</li>
                                                    {/* Early Check  In */}
                                                    {/* <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule1p}<br />
                                                        <ol className="ol2">
                                                            <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule1p_1}</li>
                                                            <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule1p_2}</li>
                                                        </ol>
                                                    </li> */}

                                                    <li className="ng-binding">
                                                        <div className='d-flex'>
                                                            <div style={{ width: '125px' }}>{LANGUAGE[data.langueage].bookingRule1p}</div>
                                                            <div>
                                                                <div>{" - " + LANGUAGE[data.langueage].bookingRule1p_1}</div>
                                                                <div>{" - " + LANGUAGE[data.langueage].bookingRule1p_2}</div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    {/* Check out late */}
                                                    <li className="ng-binding">
                                                        <div className='d-flex'>
                                                            <div style={{width:'125px'}}>{LANGUAGE[data.langueage].bookingRule2}</div>
                                                            <div>
                                                                <div>{" - " + LANGUAGE[data.langueage].bookingRule2_1}</div>
                                                                <div>{" - " + LANGUAGE[data.langueage].bookingRule2_2}</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    {/* <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule2 + " - " + LANGUAGE[data.langueage].bookingRule2_1}<br />
                                                        <div style={{ marginLeft: '115px' }}>{" - " +LANGUAGE[data.langueage].bookingRule2_2}</div>
                                                    </li> */}
                                                    <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule3}</li>
                                                    <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule4} <br />
                                                        {LANGUAGE[data.langueage].bookingRule4_1} <ol className="ol2">
                                                            <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule4_2}</li>
                                                            <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule4_3}</li>
                                                        </ol>
                                                    </li>

                                                    {
                                                        isVietNamBranch ? <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule5}<br />
                                                                                {LANGUAGE[data.langueage].bookingRule5_1} <ol className="ol2">
                                                                                    <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule5_2}</li>
                                                                                    <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule5_3}</li>
                                                                                </ol>
                                                                            </li> : ""
                                                    }
                                                    
                                                    <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule6}<br />
                                                        <ol className="ol2">
                                                            <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule6_1}</li>
                                                            <li className="ng-binding">{LANGUAGE[data.langueage].bookingRule6_2}</li>
                                                            {
                                                                isVietNamBranch && <li className="ng-binding">
                                                                {LANGUAGE[data.langueage].bookingRule6_3}
                                                                <br />
                                                                {LANGUAGE[data.langueage].bookingRule6_4}
                                                                <br />
                                                                {LANGUAGE[data.langueage].bookingRule6_5}
                                                                <br />
                                                                {LANGUAGE[data.langueage].bookingRule6_6}
                                                                </li>
                                                            }
                                                        
                                                        </ol>
                                                    </li>
                                                </ol>
                                            </div>
                                            <div className="rn-left" style={{ marginTop: (data.marginTopBottom  - 50)+ "px", }}>
                                                <span className="ng-binding" style={{ fontWeight: '700' }}>{LANGUAGE[data.langueage].receptionRegards}</span>
                                            </div>
                                            <div className="rf-left f14 mb-2">
                                                <span className="ng-binding" style={{ fontWeight: '700' }}>{LANGUAGE[data.langueage].receptionSign}</span>
                                                <input type="text" className="rcsign ml-2" value={data.maker}
                                                    onChange={e => onChangeData(e.target.value, "maker")}
                                                    style={{ border: '0px', borderBottom: '1px solid black', fontWeight: '700' }}
                                                />
                                            </div>
                                            {/* <div className="rf-left">&nbsp;</div> */}
                                        </div>
                                        <td className="col22" style={{ border: '1px solid black' }}>
                                            <div className="rh-right">
                                                <span className="ng-binding">{LANGUAGE[data.langueage].localMap}</span>
                                            </div>
                                            <div className="rmap-right">
                                                 <img 
                                                    src={`https://database.azumayareport.com/img/map/${coverNameToID(Number(branchID))}.png`} 
                                                    alt="map" 
                                                    className="img-responsive" 
                                                    style={{ width: '475px' }}
                                                />
                                            </div>  

                                            {
                                                !isVietNamBranch ?  
                                                        <div style={{textAlign:'left', marginLeft:'4px'}}>
                                                            <div style={{fontSize:'1.5rem', fontWeight:'600'}}>AZUMAYA PHNOM PENH</div>
                                                            <div className='d-flex'>
                                                                <span className='mr-5'>Tel         </span>
                                                                <span className='ml-5'>:(+855) 23 218 961</span>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <span className='mr-5'>Email       </span>
                                                                <span className='ml-4'>:reservation@azumayacambodia.com</span>
                                                            </div>
                                                        </div>
                                                            :""
                                            }

                                            <div className="rf-left f14" 
                                                 style={{ 
                                                    marginTop: (isVietNamBranch ? ((data.marginTopBottom -30) + "px") : ((data.marginTopBottom - 88) + "px")), 
                                                    textAlign: 'left' }}>
                                                <span className="ng-binding" style={{ fontWeight: '700' }}>{LANGUAGE[data.langueage].guestSign}</span>
                                                <input type="text" className="cussign ml-2"
                                                    style={{ border: '0px', borderBottom: '1px solid black' }}
                                                />
                                            </div>

                                            {/* <div className="rf-right f14"></div> */}
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td colspan="2">
                                            <span className="color-white ng-binding">{LANGUAGE[data.langueage].footer}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div> }
        </>
    )
};

export default MakeBookingBill;
